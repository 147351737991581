import { Component } from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import classNames from "classnames/bind";

// Services
import { navigate } from "../services/navigation";

// Components
import AsyncRitualButton from "./global/AsyncRitualButton";
import DiscountButtonCopy from "./bundle/DiscountButtonCopy";
import Text from "./Text";

// Utils
import { Color, Font, rem, Opacity } from "../utils/style";
import {
  getPlanForId,
  getContentfulProductForPlan,
  getProductAttributes,
} from "../utils/planToProduct";
import { getProductForContentfulId } from "../utils/planToProduct";

// Store
import { connect } from "react-redux";
import { addProductToCart } from "../store/cart/actions";
import usePromotionDiscount from "../hooks/usePromotionDiscount";

const LinkContainer = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  appearance: none;
  background: none;
  border: none;

  background-color: rgba(242, 241, 245, ${Opacity.light});
  color: ${Color.ritualBlue};

  width: 100%;
  height: 100%;
  padding: 16px 16px 16px 16px;
  border-radius: 8px;
  cursor: default !important;

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }

  &.simplified {
    cursor: pointer !important;
    background-color: ${Color.white};
    padding: 0;
  }
`;

const ProductImageWrapper = styled.button`
  height: 84px;
  cursor: pointer;
  width: 84px;
  border: none;
  background: none;
  margin: auto;
  padding: 0;
  display: flex;

  &.simplified {
    height: 112px;
    width: 112px;

    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
`;

const ProductImage = styled(GatsbyImage)`
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
`;

const ProductName = styled.h3`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  margin: 0;
  margin-bottom: 6px;
  font-weight: 500;
  letter-spacing: 0px;

  font-size: ${rem(14)};
  line-height: ${rem(18)};

  em {
    font-size: ${rem(12)};
    ${Font.dutch};
    font-style: italic;
    display: block;
    line-height: ${rem(24)};
  }
  span {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const InfoWrapper = styled.div`
  min-width: 160px;
  margin-left: 12px;
  font-size: ${rem(14)};
  overflow-x: hidden;

  &.simplified {
    margin: 12px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    em {
      line-height: ${rem(18)};
    }

    h3 {
      margin-bottom: 0;
    }
  }
`;

const QuickAddButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  a {
    padding: 0 24px;
  }
`;

const AmountWrapper = styled.div`
  margin-right: auto;
  padding-right: 20px;
`;

class QuickAddCardWide extends Component {
  constructor(props) {
    super(props);

    this.plan = getPlanForId(props.planId);
    if (!this.plan) return;

    this.contentfulProduct = getContentfulProductForPlan(props.planId);
    this.productAttributes = getProductAttributes(props.planId);
    this.productProperties = getProductForContentfulId(
      this.contentfulProduct.contentfulId,
    );
    this.handleCtaClick = this.handleCtaClick.bind(this);
    this.handleCardClick = this.handleCardClick.bind(this);
  }

  handleCtaClick(e) {
    e.stopPropagation();
    const propertiesToTrack = this.props.location
      ? { location: this.props.location }
      : {};
    this.props.dispatchAddProductToCart(
      { planId: this.plan.id },
      propertiesToTrack,
    );
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  handleCardClick() {
    // link to PDP when clicking on the card (not Cta)
    const { urlSlug } = this.productAttributes;
    navigate(`/products/${urlSlug}`);
  }

  render() {
    const { blueButton, disabled, promotion, isLink, simplified } = this.props;

    if (!this.plan) return null;

    const { upsellThumbnail, alternateThumbnail, shopImages, summary } =
      this.contentfulProduct;

    const headline = this.contentfulProduct.productSubhead;

    const flyoutImage = upsellThumbnail || alternateThumbnail;
    const image = simplified ? shopImages[0] : flyoutImage;
    return (
      <LinkContainer
        tabIndex={!simplified && -1}
        onClick={simplified && this.handleCardClick}
        className={classNames({ simplified })}
      >
        <ProductImageWrapper
          onClick={this.handleCardClick}
          className={classNames({ simplified })}
        >
          {image && (
            <ProductImage
              image={getImage(image)}
              fadeIn={true}
              alt={image.description}
              style={{
                width: "100%",
                height: "100%",
                userSelect: "none",
                userDrag: "none",
                pointerEvents: "none",
                touchCallout: "none",
                borderTopLeftRadius: "8px",
                borderBottomLeftRadius: "8px",
              }}
            />
          )}
        </ProductImageWrapper>

        <InfoWrapper className={classNames({ simplified })}>
          <ProductName>
            <em>{headline}</em>
            <span>{summary}</span>
          </ProductName>
          <QuickAddButtonWrapper>
            <AmountWrapper>
              <DiscountButtonCopy
                price={this.productAttributes.price}
                promotion={promotion}
              />
            </AmountWrapper>
            <AsyncRitualButton
              onClick={this.handleCtaClick}
              isLink={isLink}
              className={classNames("short", {
                "simple-text": simplified,
                "primary-cta": !blueButton,
              })}
              disabled={disabled}
            >
              <Text id="general.button-add" defaultMessage="Add" />
            </AsyncRitualButton>
          </QuickAddButtonWrapper>
        </InfoWrapper>
      </LinkContainer>
    );
  }
}

const withHooks = (WrappedComponent) => (props) => {
  const promotionDiscount = usePromotionDiscount(props.promotion, props.planId);
  return <WrappedComponent {...props} promotion={promotionDiscount} />;
};
const QuickAddCardWideWithHooks = withHooks(QuickAddCardWide);

export default connect(null, {
  dispatchAddProductToCart: addProductToCart,
})(QuickAddCardWideWithHooks);

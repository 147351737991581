import Model from "../model";
import { NormalizedModel } from "../utils/normalized/types";

export const ModelType = "PROMOTION-PLAN";

export default class PromotionPlan extends Model {
  // Attributes
  amountOff: number = 0;
  percentOff: number = 0;
  maxDiscount: number = 0;

  // Relationships
  promotionId: string = "";
  planId: string = "";

  constructor(attributes: { [key: string]: any }) {
    super();
    this.addAttributes(attributes);
  }

  deserialize(input: NormalizedModel) {
    this.id = input.id;
    this.addAttributes(input.attributes);

    const relationships = input.relationships;
    this.promotionId = this.getRelationshipId(relationships, "promotion");
    this.planId = this.getRelationshipId(relationships, "plan");
  }
}

import styled from "styled-components";
import classNames from "classnames";
import FocusTrap from "focus-trap-react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";

import { addProductsToCart, addProductToCart } from "../../store/cart/actions";
import { getPlanForProductSku } from "../../utils/planToProduct";
import { getPlanIdAndQuantityArray } from "../../utils/bundle";
import keyCodes from "../../utils/keyCode";
import intl from "../../services/intl";
import cartService from "../../services/cart";

import {
  CaptionTitle,
  ImageContainer,
  PriceBreakdown,
  QuickView,
  QuickViewFooter,
  QuickViewHeader,
  QuickViewItem,
  RCloseButton,
  RPrice,
  TextBlock,
  TitleBlock,
} from "@ritual/essentials-for-react";
import Currency from "../Currency";
import RitualButton from "../global/RitualButton";

const QuickViewWrapper = styled.div`
  .quick-view-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .quick-view-item-list {
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &.no-quick-view-footer .quick-view-item-list {
    height: 100%;
  }

  &.no-overlay overlay-box {
    display: none;
  }
`;

const QuickViewItemProductImage = styled.div`
  border-radius: 48%;
  overflow: hidden;
`;

type Content = {
  title: string;
  slug: string;
  sku: string;
  image: {
    gatsbyImageData: any;
    description: string;
  };
  shortDescription: string;
  products: any[];
  name?: string;
  ritualProductOffer: {
    product_offer_id: string;
  };
};

type Item = {
  content: Content;
  futurePrice: string;
  price: string;
  savings: string;
};

type QuickViewProps = {
  open: boolean;
  item: Item;
  closeQuickView: () => void;
  showCTA: boolean;
  showOverlay: boolean;
  location?: string;
};

const QuickViewComponent = ({
  open,
  item,
  closeQuickView,
  showCTA = true,
  showOverlay = true,
  location,
}: QuickViewProps) => {
  if (!item) return null;

  const { content } = item;
  const { title, products, ritualProductOffer } = content;
  content.name = title;

  const productCount = products?.length;

  const handleEscDown: any = (e: any) => {
    if (e.keyCode === keyCodes.ESC && open) {
      closeQuickView();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleEscDown);

    return () => {
      window.removeEventListener("keydown", handleEscDown);
    };
  }, [handleEscDown]);

  const [initialOverflow] = useState(document.body.style.overflow);
  useEffect(() => {
    if (initialOverflow === "hidden") return;

    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [open, initialOverflow]);

  const dispatch = useDispatch();
  const plans = products
    ?.map((p) => getPlanForProductSku(p.sku))
    .filter((p) => p);
  const handleAddBundleProduct = (ev: Event) => {
    if (ritualProductOffer) {
      dispatch(
        addProductToCart(
          {
            productOfferId: ritualProductOffer.product_offer_id,
          },
          {
            location,
          },
        ),
      );
    } else {
      const plansToAdd = getPlanIdAndQuantityArray(plans.map((p) => p.id));
      dispatch(addProductsToCart(plansToAdd));
    }
    cartService.openCart(ev);
    closeQuickView();
  };

  return (
    <FocusTrap
      active={open}
      focusTrapOptions={{
        allowOutsideClick: true,
        initialFocus: "#quick-view-focus",
        fallbackFocus: "#quick-view-focus",
        tabbableOptions: {
          getShadowRoot: true,
        },
      }}
    >
      <QuickViewWrapper
        className={classNames({
          "no-quick-view-footer": !showCTA,
          "no-overlay": !showOverlay,
        })}
      >
        <QuickView open={open} closeQuickView={closeQuickView}>
          <QuickViewHeader slot="quick-view-header">
            <span slot="label">
              {intl.t("plp.quick-view.title", "Quick View")}
            </span>
            <RCloseButton
              slot="quick-view-header-close"
              handleClose={closeQuickView}
              id={showCTA ? "" : "quick-view-focus"}
            ></RCloseButton>
            <span slot="title">{title}</span>
            <CaptionTitle slot="quick-view-header-caption">
              {`${productCount} products in this bundle`}
            </CaptionTitle>
          </QuickViewHeader>

          {products &&
            products.map((product: any, i: number) => {
              const {
                name: { name: productName },
                image,
                shortDescription,
                supplementFacts,
              } = product;
              const { servingsPerContainer, servingSize, simpleIngredients } =
                supplementFacts;

              const ingredientList = simpleIngredients.map(
                (ingredient: any) => ingredient.name,
              );
              const hasPowderServing = servingSize
                .toLowerCase()
                .includes("scoop");
              const splitOnChar = servingSize.split("(");
              const servingContainerLabel = hasPowderServing
                ? splitOnChar.join().split(" ,")[0]
                : splitOnChar[1]
                    ?.toLowerCase()
                    .replace(")", "")
                    .replace("vegan", "");

              const showProductFootnote = product.sku === "STRS";

              return (
                <QuickViewItem
                  slot="quick-view-items"
                  key={`quick-view-item-${i}`}
                >
                  <TitleBlock slot="quick-view-item-title-block">
                    {productName}
                  </TitleBlock>
                  {image && (
                    <ImageContainer
                      slot="quick-view-item-image"
                      styleString="round"
                    >
                      <QuickViewItemProductImage slot="image-container-insert">
                        <GatsbyImage
                          image={
                            getImage(image.gatsbyImageData) as IGatsbyImageData
                          }
                          alt={image.description}
                        />
                      </QuickViewItemProductImage>
                    </ImageContainer>
                  )}
                  <CaptionTitle slot="quick-view-item-caption-title">
                    {intl.t("plp.quick-view.serving-size", "Serving Size: ", {
                      amount: servingContainerLabel,
                    })}
                  </CaptionTitle>
                  <CaptionTitle slot="quick-view-item-caption-title">
                    {intl.t(
                      "plp.quick-view.servings",
                      "Servings Per Container: ",
                      {
                        amount: servingsPerContainer,
                      },
                    )}
                  </CaptionTitle>
                  <TextBlock slot="quick-view-text-block">
                    <CaptionTitle slot="text-block-caption-title">
                      {intl.t("plp.quick-view.overview", "Overview")}
                    </CaptionTitle>
                    <span slot="text-block-content">{shortDescription}</span>
                  </TextBlock>

                  <TextBlock slot="quick-view-text-block">
                    <CaptionTitle slot="text-block-caption-title">
                      {intl.t("plp.quick-view.ingredients", "Ingredients")}
                    </CaptionTitle>
                    <span slot="text-block-content">
                      {ingredientList.join(", ")}
                    </span>
                  </TextBlock>

                  {showProductFootnote && (
                    <CaptionTitle slot="quick-view-item-citation">
                      {intl.t(
                        "plp.quick-view.product-footnote",
                        "†8 hour release",
                      )}
                    </CaptionTitle>
                  )}
                </QuickViewItem>
              );
            })}

          {showCTA && (
            <QuickViewFooter slot="quick-view-footer">
              <PriceBreakdown slot="quick-view-footer-price" spread={true}>
                <RPrice slot="price-breakdown-final" priceClass="final">
                  {/* @ts-ignore */}
                  <Currency value={item.price} round={true} />
                </RPrice>
                {item.futurePrice && (
                  <RPrice
                    slot="price-breakdown-strikethrough"
                    priceClass="strikethrough"
                  >
                    {/* @ts-ignore */}
                    <Currency value={item.futurePrice} round={true} />
                  </RPrice>
                )}
                {item.savings && (
                  <CaptionTitle slot="price-breakdown-savings-caption">
                    {/* @ts-ignore */}
                    <Currency value={item.savings} round={true} />{" "}
                    {intl.t("plp.quick-view.savings", "savings")}
                  </CaptionTitle>
                )}
              </PriceBreakdown>
              <span slot="quick-view-footer-cta-button">
                <RitualButton
                  onClick={handleAddBundleProduct}
                  className={"primary fullwidth"}
                  id="quick-view-focus"
                  tabIndex={0}
                  isLink={false}
                >
                  Add to Cart
                </RitualButton>
              </span>
            </QuickViewFooter>
          )}
        </QuickView>
      </QuickViewWrapper>
    </FocusTrap>
  );
};

export default QuickViewComponent;

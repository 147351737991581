import { RNavigation } from "@ritual/essentials-for-react";
import { useSelector } from "react-redux";
import useNavigation from "../../hooks/contentful/useNavigation";
import useVariation from "../../hooks/useVariation";
import cartService from "../../services/cart";
import { navigate } from "../../services/navigation";
import userSelectors from "../../store/user/selectors";
import metrics from "../../utils/metrics";

const NavigationMenu = ({ open, handleClose }) => {
  const navigationFlag = useVariation("navigation", { disabled: true });
  const navigation = useNavigation(navigationFlag.panelId);
  const authenticated = useSelector(userSelectors.isLoggedIn);

  const segmentData = navigation?.panels?.flatMap((panel) =>
    panel.linkSets.flatMap((linkSet) =>
      linkSet.links.map((link) => ({
        destination: link?.text,
        destinationUrl: link?.to?.path,
        panelId: panel?.panelId,
        panelName: panel?.name,
        linkSetId: linkSet?.linkSetId,
        linkSetName: linkSet?.name,
        linkId: link?.linkId,
      })),
    ),
  );

  const findSegmentData = (linkId, linkSetId) =>
    segmentData.find((d) => d.linkId === linkId && d.linkSetId === linkSetId);

  const handleSegmentEvent = (event = "Nav Item Clicked", data = {}) => {
    metrics.track(event, data);
  };

  /**
   * Handle clicking the close icon
   */
  const handleCloseClick = () => {
    handleClose(0, false);

    handleSegmentEvent("Nav Closed", {});
  };

  /**
   * Handle clicking the cart icon
   */
  const handleCartClick = () => {
    handleClose();

    handleSegmentEvent("Nav Item Clicked", {
      destination: "Cart",
    });

    cartService.openCart();
  };

  /**
   * Handle clicking the account icon
   */
  const handleAccountClick = () => {
    const accountUrl = process.env.GATSBY_ACCOUNT_URL;

    handleSegmentEvent("Nav Item Clicked", {
      destination: "Account",
      destinationUrl: accountUrl,
    });
    window.location.href = accountUrl;
  };

  /**
   * Handle clicking a navigation link
   */
  const handleLinkClick = (linkId, linkSetId) => {
    const data = findSegmentData(linkId, linkSetId);

    handleSegmentEvent("Nav Item Clicked", {
      ...data,
    });

    const url = data.destinationUrl;
    if (url) {
      const isExternal = url.startsWith("http");
      if (isExternal) {
        handleClose(0, false);
        window.open(url, "_blank").focus();
      } else {
        handleClose(0, false);
        navigate(`/${url}`);
      }
    }
  };

  if (navigation?.panels) {
    return (
      <RNavigation
        data={navigation.panels}
        open={open}
        loggedIn={authenticated}
        closeFn={handleCloseClick}
        cartFn={handleCartClick}
        accountFn={handleAccountClick}
        linkClickFn={handleLinkClick}
      />
    );
  }

  return null;
};

export default NavigationMenu;

import Model from "../model";
import { NormalizedModel } from "../utils/normalized/types";

export const ModelType = "CART";

interface BillingDetailsPlan {
  id: string;
  quantity: number;
}

interface BillingDetails {
  amount: number;
  next_bill_date: string;
  plans: Array<BillingDetailsPlan>;
}

export interface RecurringBillingDetails {
  [key: string]: BillingDetails;
}

interface ShippingAddress {
  state?: string;
  validated?: boolean;
}

interface GiftCard {
  amount: number;
  code: string;
}

export default class Cart extends Model {
  // Attributes
  status: string = "";
  discountCode: string = "";
  discountAmount: number = 0;
  productOfferDiscountAmount: number = 0;
  subtotal: number = 0;
  totalTax: number = 0;
  total: number = 0;
  currency: string = "";
  creditsUsed: number = 0;
  giftCards: GiftCard[] = [];
  giftCardCodes: string[] = [];
  shippingAddress: ShippingAddress = {};
  creditRemaining: number = 0;
  giftCardAmountUsed: number = 0;
  productRecommendations: Array<string> = [];
  shippingAmount: number = 0;
  taxInclusive: boolean = false;
  recurringBillingDetails: RecurringBillingDetails = {};
  requiresPaymentIntent: boolean = false;
  requiresSetupIntent: boolean = false;

  // BelongsTo Relationships
  userId: string = "";
  guestId: string = "";
  couponId: string = "";
  promotionId: string = "";
  productPromotionId: string = "";
  shippingOptionId: string = "";

  // HasMany Relationships
  activePromotionIds: Array<string> = [];
  activeShippingOptionIds: Array<string> = [];

  constructor(attributes: { [key: string]: any }) {
    super();
    this.addAttributes(attributes);
  }

  get prediscountTotal(): number {
    return this.subtotal + this.discountAmount;
  }

  get preTaxTotal(): number {
    return this.total - this.totalTax;
  }

  deserialize(input: NormalizedModel) {
    this.id = input.id;

    this.addAttributes(input.attributes);

    const relationships = input.relationships;

    // BelongsTo
    this.userId = this.getRelationshipId(relationships, "user");
    this.guestId = this.getRelationshipId(relationships, "guest");
    this.couponId = this.getRelationshipId(relationships, "coupon");
    this.promotionId = this.getRelationshipId(relationships, "promotion");
    this.productPromotionId = this.getRelationshipId(
      relationships,
      "product_promotion",
    );
    this.shippingOptionId = this.getRelationshipId(
      relationships,
      "shipping_option",
    );

    // HasMany
    this.activePromotionIds = this.getRelationshipIds(
      relationships,
      "active_promotions",
    );
    this.activeShippingOptionIds = this.getRelationshipIds(
      relationships,
      "active_shipping_options",
    );
  }

  serialize(updatedAttributes: { [key: string]: any }) {
    const attributes = {
      ...this,
      ...updatedAttributes,
    };
    return this.serializeInternal(attributes);
  }
}

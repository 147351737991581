import styled from "styled-components";

// Components
import AsyncRitualButton from "../global/AsyncRitualButton";
import Text from "../Text";
import ShippingProgress from "./ShippingProgress";
import StripeExpressCheckout from "./StripeExpressCheckout";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { reserveInput } from "../../store/apply-code/actions";
import cartProductSelectors from "../../store/cart-product/selectors";

// Hooks
import useVariation from "../../hooks/useVariation";
import { useShipping } from "../../hooks/useShipping";

// Utils
import { formatTotal } from "../../utils/cart";
import { Color } from "../../utils/style";
import { getCheckoutUrlWithoutPlan } from "../../utils/urlBuilder";
import { trackCheckoutCTAClicked } from "../../utils/tracking/cart";
import RitualButton from "../global/RitualButton";

const BottomCheckoutButtonContainer = styled.div`
  width: 100%;
  padding-top: 12px;
  margin-bottom: calc(16px + env(safe-area-inset-bottom));
  border-top: 1px solid ${Color.veryFadedGrey};
`;

const BottomCheckoutButton = styled(AsyncRitualButton)`
  margin-top: 8px;
  width: 100%;
  height: 48px;
`;

const BottomReactCheckoutButton = styled(RitualButton)`
  margin-top: 8px;
  width: 100%;
  height: 48px;
`;

const EstimatedTotal = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  p {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 24px;
    margin-bottom: 4px;
  }

  p:last-of-type {
    font-weight: 300;
    text-align: right;
  }

  ${(p) => p.isPromo && "color:" + Color.ritualGreen + ";"}
`;

const BottomTotal = styled.div``;

export default function FlyoutCartBottom({
  activeCart,
  isAddingToSubscription,
  isProcessing,
  cartProducts,
  cartQuantity,
  activeCoupon,
  appliedPromotion,
}) {
  const checkoutFeatureFlag = useVariation("react-checkout-enabled");
  const dispatch = useDispatch();
  const checkoutUrl = getCheckoutUrlWithoutPlan();
  const productOfferLedgerFeatureFlag = useVariation(
    "product-offer-ledger-discount",
  );
  const canCheckout = !!(!isProcessing && cartQuantity);

  // For threshold, use the shipping option that is applied to the cart
  const shipping = useShipping();
  const shippingProgress = useVariation("shipping-progress");
  const shouldShowProductOfferDiscount =
    activeCart?.productOfferDiscountAmount > 0 && productOfferLedgerFeatureFlag;

  // TODO: Cleanup when we revise Express Checkout
  const activeCartOneTimeAny = useSelector(
    cartProductSelectors.activeCartOneTimeAny,
  );

  const expressCheckoutEligible =
    canCheckout &&
    !activeCartOneTimeAny &&
    !isAddingToSubscription &&
    activeCart.total > 0;

  async function handleCheckoutCTAClicked() {
    if (!canCheckout) return;
    await trackCheckoutCTAClicked();
    await dispatch(reserveInput());
  }

  return (
    <BottomCheckoutButtonContainer>
      {shipping && shippingProgress && (
        <ShippingProgress copy={shipping.copy} percent={shipping.percent} />
      )}
      <BottomTotal>
        {activeCoupon && (
          <EstimatedTotal isPromo={true}>
            <p>
              <Text id="cart.receipt.promo-label" defaultMessage="Promo:" />{" "}
              <em>{activeCoupon.code}</em>
            </p>
            <p>{formatTotal(activeCart.discountAmount, true, isProcessing)}</p>
          </EstimatedTotal>
        )}
        {appliedPromotion && cartQuantity > 0 && (
          <EstimatedTotal isPromo={true}>
            <p>{appliedPromotion.displayName}</p>
            <p>{formatTotal(activeCart.discountAmount, true, isProcessing)}</p>
          </EstimatedTotal>
        )}
        {shouldShowProductOfferDiscount && (
          <EstimatedTotal isPromo={true}>
            <p>
              <Text
                id="cart.receipt.product-offer-discount-label"
                defaultMessage="Bundle Discount"
              />
            </p>
            <p>
              {formatTotal(
                activeCart.productOfferDiscountAmount,
                true,
                isProcessing,
              )}
            </p>
          </EstimatedTotal>
        )}
        <EstimatedTotal>
          <p>
            <Text id="cart.receipt.subtotal" defaultMessage="Subtotal" />
          </p>
          <p>
            {formatTotal(
              activeCart && activeCart.subtotal,
              false,
              isProcessing,
              true,
            )}
          </p>
        </EstimatedTotal>
        <EstimatedTotal>
          <p>
            <Text id="cart.receipt.shipping" defaultMessage="Shipping" />
          </p>
          <p>
            {activeCart?.shippingAmount ? (
              formatTotal(
                activeCart && activeCart.shippingAmount,
                false,
                isProcessing,
                true,
              )
            ) : (
              <Text id="cart.receipt.free" defaultMessage="Free" />
            )}
          </p>
        </EstimatedTotal>
      </BottomTotal>

      {expressCheckoutEligible && (
        <StripeExpressCheckout
          cart={activeCart}
          cartProducts={cartProducts}
          coupon={activeCoupon}
        />
      )}

      {checkoutFeatureFlag && !isAddingToSubscription ? (
        <BottomReactCheckoutButton
          disabled={!canCheckout}
          to="/checkout"
          className="fullwidth"
          onClick={handleCheckoutCTAClicked}
        >
          <Text
            id="cart.flyout.button-checkout"
            defaultMessage="Continue to Checkout"
          />
        </BottomReactCheckoutButton>
      ) : (
        <BottomCheckoutButton
          disabled={!canCheckout}
          target="_self"
          onClick={handleCheckoutCTAClicked}
          href={canCheckout ? checkoutUrl : undefined}
        >
          <Text
            id="cart.flyout.button-checkout"
            defaultMessage="Continue to Checkout"
          />
        </BottomCheckoutButton>
      )}
    </BottomCheckoutButtonContainer>
  );
}

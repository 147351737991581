import { Component } from "react";
import styled from "styled-components";

import { Opacity } from "../../utils/style";

const Strike = styled.em`
  opacity: ${Opacity.light};
  text-decoration: line-through;
  margin-left: var(--spacing-0_5);
  font-style: normal;

  ${(p) => p.contentStyle};
`;

export default class StrikeText extends Component {
  render() {
    const { className, contentStyle } = this.props;
    return (
      <Strike className={className} contentStyle={contentStyle}>
        {this.props.children}
      </Strike>
    );
  }
}

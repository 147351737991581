import { Component } from "react";
import styled from "styled-components";
import { Color, Font, Opacity, rem, responsive, rgba } from "../../utils/style";

import { connect } from "react-redux";
import intl from "../../services/intl";
import {
  clearInput,
  removeInput,
  reserveInput,
  updateInput,
} from "../../store/apply-code/actions";

import couponSelectors from "../../store/coupon/selectors";
import Text from "../Text";

const PromoCodeAction = {
  Apply: "apply",
  Cancel: "cancel",
  Remove: "remove",
};

const PromoCodeWrapper = styled.div``;

const AddButton = styled.button`
  height: 26px;
  padding: 0;

  background: transparent;
  border: none;

  border-bottom: 2px solid ${Color.ritualBlue};

  color: ${Color.ritualBlue};
  font-weight: 500;

  font-size: ${rem(14)};
  ${responsive.md`
    font-size: ${rem(16)};
  `}

  opacity: ${Opacity.light};
`;

const PromoCodeFormWrapper = styled.form`
  background-color: ${Color.white};
`;

const FormButton = styled.button`
  margin-right: 16px;
  margin-top: 8px;
  padding: 0;

  height: 24px;

  background: transparent;
  border: none;

  border-bottom: 2px solid
    ${(p) => (p.isError ? Color.ritualRed : Color.ritualBlue)};

  color: ${(p) => (p.isError ? Color.ritualRed : Color.ritualBlue)};

  font-size: ${rem(16)};
  font-weight: 500;

  &:hover {
    opacity: ${Opacity.light};
  }

  &:disabled {
    opacity: 0.24;
  }
`;

const Message = styled.div`
  position: absolute;
  bottom: -26px;

  p {
    font-size: ${rem(12)};
    font-weight: 500;
    line-height: ${rem(18)};
    margin: 0;
  }
  color: ${(p) => (p.isError ? Color.ritualRed : Color.ritualGreen)};
`;

const FormInputWrapper = styled.div`
  position: relative;
  display: flex;

  margin-bottom: 26px;

  input {
    height: 44px;
    ${Font.circular} width: 100%;
    color: ${(p) => (p.isError ? Color.ritualRed : Color.ritualBlue)};
    padding: 0 16px;
    background: transparent;
    font-weight: 300;
    font-size: ${rem(16)};
    line-height: 1;
    outline: none;
    line-height: ${rem(22)};
    border: 0;

    &::placeholder {
      ${Font.circular};
      color: ${rgba(Color.ritualBlue, Opacity.light)};
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      // Workaround to prevent Chrome blue autofill
      // https://stackoverflow.com/a/55706002
      box-shadow: 0 0 0px 1000px #ffffff inset !important;
    }
  }

  border-radius: 1px;
  border: 1px solid
    ${(p) => (p.isError ? Color.ritualRed : Color.ritualSecondaryDisabledBlue)};
`;

const component = class PromoCode extends Component {
  constructor() {
    super();

    this.state = {
      isCollapsed: true,
    };

    this.handleChange = this.handleChange.bind(this);

    this.handleAdd = this.handleAdd.bind(this);
    this.handleApply = this.handleApply.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  componentWillUnmount() {
    const { applyCode } = this.props;
    const { code, hasBeenApplied, isValid } = applyCode;
    if (code && hasBeenApplied && !isValid) {
      this.props.dispatchClearInput();
    }
  }

  static getDerivedStateFromProps(props, state) {
    // Expand the input if the code is set.
    if (props.applyCode.code && state.isCollapsed) {
      return {
        isCollapsed: false,
      };
    }
    // Return null if the state hasn't changed.
    return null;
  }

  handleChange(e) {
    // Remove white space automatically
    const value = e.target.value.replace(/\s/g, "");

    this.props.dispatchUpdateInput(value);
  }

  handleAdd() {
    this.setState({
      isCollapsed: false,
    });
  }

  handleApply() {
    this.validateCode();
  }

  handleCancel() {
    this.props.dispatchClearInput();
    this.setState({
      isCollapsed: true,
    });
  }

  async handleRemove() {
    await this.props.dispatchRemoveInput();
    this.setState({
      isCollapsed: true,
    });
  }

  _getPromoCodeAction(isEmpty, hasBeenApplied, isValid) {
    if (!hasBeenApplied && !isEmpty) {
      return PromoCodeAction.Apply;
    } else if (isEmpty || (hasBeenApplied && !isValid)) {
      return PromoCodeAction.Cancel;
    } else if (hasBeenApplied && isValid) {
      return PromoCodeAction.Remove;
    }
    return null;
  }

  handleSubmit(isEmpty, hasBeenApplied, isValid, e) {
    e.preventDefault();

    const promoCodeAction = this._getPromoCodeAction(
      isEmpty,
      hasBeenApplied,
      isValid,
    );

    switch (promoCodeAction) {
      case PromoCodeAction.Apply:
        this.handleApply();
        break;
      case PromoCodeAction.Cancel:
        this.handleCancel();
        break;
      case PromoCodeAction.Remove:
        this.handleRemove();
        break;
      default:
        break;
    }
  }

  renderButton(isEmpty, isError, hasBeenApplied, isValid, isProcessing) {
    let title = "";

    const promoCodeAction = this._getPromoCodeAction(
      isEmpty,
      hasBeenApplied,
      isValid,
    );

    switch (promoCodeAction) {
      case PromoCodeAction.Apply:
        title = intl.t("cart.promo-code.action-apply", "Apply");
        break;
      case PromoCodeAction.Cancel:
        title = intl.t("cart.promo-code.action-cancel", "Cancel");
        break;
      case PromoCodeAction.Remove:
        title = intl.t("cart.promo-code.action-remove", "Remove");
        break;
      default:
        break;
    }

    if (!title) return;

    return (
      <FormButton disabled={isProcessing} type="submit" isError={isError}>
        {title}
      </FormButton>
    );
  }

  getInputMessage(isError, activeCoupon) {
    if (isError) {
      return intl.t("cart.promo-code.error", "Bummer, that's an invalid code.");
    }

    // If there's no activeCoupon, do not show an input message.
    if (!activeCoupon) return "";

    const { amountOff, percentOff } = activeCoupon;

    if (amountOff || percentOff) {
      if (percentOff) {
        return intl.t(
          "cart.promo-code.percent-off",
          `You're in! {percent}% off applied.`,
          { percent: percentOff },
        );
      } else if (amountOff) {
        return intl.t(
          "cart.promo-code.amount-off",
          "You're in! {amount} off applied.",
          { amount: intl.formatCurrency(amountOff / 100) },
        );
      }
    }
    return "";
  }

  async validateCode() {
    await this.props.dispatchReserveInput();
  }

  render() {
    const { activeCoupon, applyCode, isProcessing } = this.props;
    const { code, hasBeenApplied, isValid } = applyCode;
    const { isCollapsed } = this.state;

    const isEmpty = !code;
    const isError = hasBeenApplied && !isValid;
    const message = this.getInputMessage(isError, activeCoupon);

    return (
      <PromoCodeWrapper>
        <AddButton
          onClick={this.handleAdd}
          aria-expanded={!isCollapsed}
          aria-controls="promo-code-form"
          className={isCollapsed ? "" : "sr-only"}
        >
          <Text id="cart.promo-code.apply" defaultMessage="Apply Promo Code" />
        </AddButton>
        {!isCollapsed && (
          <PromoCodeFormWrapper
            id="promo-code-form"
            onSubmit={(e) =>
              this.handleSubmit(isEmpty, hasBeenApplied, isValid, e)
            }
          >
            <FormInputWrapper isError={isError}>
              <input
                type="text"
                id="promo-code-input"
                aria-label={intl.t("cart.promo-code.input-label", "Promo Code")}
                value={code}
                onChange={this.handleChange}
                placeholder={intl.t(
                  "cart.promo-code.input-placeholder",
                  "Enter Promo Code",
                )}
                readOnly={isValid}
                disabled={isProcessing}
              />
              {this.renderButton(
                isEmpty,
                isError,
                hasBeenApplied,
                isValid,
                isProcessing,
              )}
              {message && (
                <Message isError={isError} aria-live="assertive">
                  <p>{message}</p>
                </Message>
              )}
            </FormInputWrapper>
          </PromoCodeFormWrapper>
        )}
      </PromoCodeWrapper>
    );
  }
};

const mapStateToProps = (state) => {
  const { applyCode } = state;
  return {
    activeCoupon: couponSelectors.activeCoupon(state),
    applyCode,
  };
};

export default connect(mapStateToProps, {
  // Update Apply Code Actions
  dispatchUpdateInput: updateInput,
  dispatchRemoveInput: removeInput,
  dispatchClearInput: clearInput,
  dispatchReserveInput: reserveInput,
})(component);

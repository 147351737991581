import intlService from "../services/intl";
import useReferralAccountDashboard from "./launchdarkly/useReferralAccountDashboard";

type ReferralDashboard = {
  enabled: boolean;
};

const validLocales = ["en-US"];

const useReferralDashboard = (): ReferralDashboard => {
  const ldReferral = useReferralAccountDashboard();

  const { locale } = intlService;
  const isValidLocale = validLocales.includes(locale);

  const enabled = ldReferral.enable && isValidLocale;

  return { enabled };
};

export default useReferralDashboard;

import styled from "styled-components";
import CartProduct from "../../store/cart-product/model";
import CartItem from "../cart/CartItem";
import {
  getContentfulProductForPlan,
  getPlanForId,
} from "../../utils/planToProduct";
import Text from "../Text";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import cartProductSelectors from "../../store/cart-product/selectors";

const CartItemsList = styled.div`
  transition: height 0.3s ease;
  overflow: hidden;

  & > *:last-child::before {
    display: none !important;
  }
`;

const CadenceTitle = styled.h3`
  position: relative;
  z-index: 10;
  margin: -1px 0 0 0;
  color: var(--indigo-blue-60);
  background-color: var(--white);
  display: flex;
  align-items: center;

  span.title-text {
    flex: 0 0 auto;
    padding-right: var(--spacing-0_5);
  }

  span.title-line {
    width: 100%;
    flex: 1 1 auto;
    border-top: 1px solid var(--cool-40);
  }
`;

interface LedgerItemsProps {
  activeCartProducts: CartProduct[];
  collapsed?: boolean;
  disableAnimation?: boolean;
  isProcessing?: boolean;
  cartItemOptions?: any;
}

const getIntervalCount = (cartProduct: CartProduct) => {
  let intervalCount;
  if (cartProduct?.planId) {
    const plan = getPlanForId(cartProduct.planId);
    if (!plan) return null;
    intervalCount = plan.intervalCount || 0;
  }
  if (cartProduct?.productOfferId) {
    // TODO: Interval Count can be assumed 30 for now
    intervalCount = 30;
  }
  return intervalCount;
};

const LedgerItems = ({
  activeCartProducts,
  collapsed,
  disableAnimation,
  isProcessing,
  cartItemOptions = {
    hideDeleteButton: true,
    disableQuantitySelector: true,
  },
}: LedgerItemsProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [cartProducts, setCartProducts] = useState<CartProduct[]>([]);
  const [finalCount, setFinalCount] = useState<number | null>(null);

  const cartQuantity = useSelector(
    cartProductSelectors.activeCartProductQuantity,
  );

  useEffect(() => {
    if (activeCartProducts.length && activeCartProducts.length === finalCount) {
      setCartProducts(activeCartProducts);
    }
  }, [activeCartProducts, finalCount]);

  useEffect(() => {
    const stabilizeData = () => {
      const currentCount = activeCartProducts.length;
      if (currentCount > 0 && currentCount !== finalCount) {
        setFinalCount(currentCount);
      }
    };

    stabilizeData();
  }, [activeCartProducts, finalCount]);

  if (!cartProducts || cartProducts.length < 1) return null;

  const addIntervalCountToCartProducts = cartProducts.map((cartProduct) => {
    return {
      ...cartProduct,
      intervalCount: getIntervalCount(cartProduct),
    };
  });
  const sortedData = addIntervalCountToCartProducts.sort(
    (a, b) => a.intervalCount - b.intervalCount,
  );

  const result: any[] = [];
  let lastIntervalCount: null | number = null;

  sortedData.forEach((item) => {
    if (item.intervalCount !== lastIntervalCount) {
      result.push(
        <CadenceTitle
          key={`title-${item.intervalCount}`}
          className="typography-caption"
        >
          <span className="title-text">
            <Text
              id={`checkout.cart-ledger.cadence-title-${item.intervalCount}`}
              defaultMessage=""
            />
          </span>
          <span className="title-line" />
        </CadenceTitle>,
      );
      lastIntervalCount = item.intervalCount;
    }
    const contentfulProduct = getContentfulProductForPlan(item?.planId);
    result.push(
      <CartItem
        key={item.key}
        className={"simplified"}
        cartProduct={item}
        contentfulProduct={contentfulProduct}
        isProcessing={isProcessing}
        cartQuantity={cartQuantity}
        simplified={true}
        isFlyoutContext={false}
        hideDeleteButton={cartItemOptions.hideDeleteButton}
        disableQuantitySelector={cartItemOptions.disableQuantitySelector}
      />,
    );
  });

  const height = collapsed
    ? "0px"
    : `${(contentRef?.current?.scrollHeight || 1) - 1}px`;

  return (
    <CartItemsList
      ref={contentRef}
      style={{
        height: disableAnimation ? "auto" : height,
      }}
    >
      {result}
      <div></div>
    </CartItemsList>
  );
};

export default LedgerItems;

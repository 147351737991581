import useVariation from "../useVariation";

type ReferralAccountDashboard = {
  enable: boolean;
};

const defaultValue: ReferralAccountDashboard = { enable: true };

const isValidReferralAccountDashboard = (
  value: any,
): value is ReferralAccountDashboard =>
  typeof value === "object" &&
  value !== null &&
  "enable" in value &&
  typeof value.enable === "boolean";

const useReferralAccountDashboard = (): ReferralAccountDashboard => {
  const value = useVariation("referral-account-dashboard", defaultValue);

  return isValidReferralAccountDashboard(value) ? value : defaultValue;
};

export default useReferralAccountDashboard;

import queryString from "query-string";
import Cart, { ModelType } from "./model";
import cartSelectors from "./selectors";

// Normalized
import normalizedActions from "../utils/normalized/actions";
import fetch from "../utils/normalized/fetch";

// Cart Product
import {
  addCartProduct,
  removeCartProduct,
  removeCartProducts,
} from "../cart-product/actions";
import CartProduct from "../cart-product/model";
import cartProductSelectors from "../cart-product/selectors";

// Coupon
import couponSelectors from "../coupon/selectors";

// Plan
import planSelectors from "../plan/selectors";

// Services
import siteStore from "../../services/siteStore";

// Utils
import { getBundleCartLimit } from "../../utils/bundle";
import errorReporter from "../../utils/errorReporter";
import fetchInternal from "../../utils/fetch";
import {
  trackBundleAdded,
  trackCartUpdated,
  trackProductAdded,
  trackProductRemoved,
  trackBundleRemoved,
} from "../../utils/tracking/cart";
import {
  trackCouponAdded,
  trackCouponDenied,
  trackCouponRemoved,
} from "../../utils/tracking/coupon";
import { addPendingCode } from "../pending-code/actions";
import { productOfferForId } from "../product-offer/selectors";
import { addPromotionIntent } from "../promotion-intent/actions";
import { refreshBestEligiblePromotion } from "../promotion/actions";

export function fetchCart(options = { bypassProcessing: false }) {
  return _createAsyncAction(
    "fetchCart",
    async (dispatch) => {
      await dispatch(_fetchCart());
      await dispatch(_tryApplyPromotionIntent());
    },
    options.bypassProcessing,
  );
}

function getNewQuantity(state, productOfferId) {
  const currentQuantity = cartProductSelectors.activeCartProductQuantity(state);

  if (productOfferId) {
    return (
      currentQuantity +
      getProductOffer(state, productOfferId).productQuantity(state)
    );
  }
  return currentQuantity + 1;
}

export function addProductToCart(productData, propertiesToTrack = {}) {
  const { planId, productOfferId } = productData;

  return _createAsyncAction(
    "addProductToCart",
    (dispatch, getState) => {
      const state = getState();
      const activeCart = cartSelectors.activeCart(state);

      // If there's no active cart, queue the action
      if (!activeCart) {
        dispatch(updateCartQueue([{ planId, propertiesToTrack }]));
        return Promise.resolve();
      }

      const productLimit = getBundleCartLimit();

      const currentQuantity =
        cartProductSelectors.activeCartProductQuantity(state);

      if (currentQuantity > productLimit) {
        throw new Error("Too many products exist on the cart!");
      }

      // If we've reach the product limit, show the user a banner and do not add
      // the product.
      const newQuantity = getNewQuantity(state, productOfferId);
      if (newQuantity > productLimit) {
        dispatch(updateLimitBanner(true));
        return Promise.resolve();
      }

      // Get the existing cartProduct for the current planId.
      const cartProduct = _getActiveCartProduct(state, productData);

      const cartProductAlreadyExistsForTheProductOffer =
        productOfferId && cartProduct;
      if (cartProductAlreadyExistsForTheProductOffer) {
        dispatch(updateProductOfferLimitBanner(true));
        return Promise.resolve();
      }

      // If a cart product for the current planId already exists, update the
      // quantity instead.
      if (cartProduct && cartProduct.quantity > 0) {
        return dispatch(
          _updateCartProductQuantity(
            activeCart.id,
            cartProduct,
            1,
            propertiesToTrack,
          ),
        );
      }

      const stubCartProduct = _createStubCartProduct(
        state,
        activeCart.id,
        productData,
      );
      // Add the stub cartProduct to the store.
      dispatch(addCartProduct(stubCartProduct));

      let propertiesToTrackWithProductOffer = { ...propertiesToTrack };

      if (productOfferId) {
        const productOffer = getProductOffer(state, productOfferId);

        propertiesToTrackWithProductOffer = {
          ...propertiesToTrackWithProductOffer,
          product_offer_id: productOfferId,
          product_offer_name: productOffer?.name,
        };
        trackBundleAdded(productOfferId, propertiesToTrackWithProductOffer);
      } else {
        trackProductAdded(planId, propertiesToTrack);
      }

      // Issue the request to add the product to the cart.
      return dispatch(
        _postCartProduct(activeCart.id, { planId, productOfferId }),
      )
        .then(async () => {
          const applyCode = state.applyCode;
          if (applyCode.code) {
            await dispatch(addPendingCode(applyCode.code));
          }

          // Track cart update
          trackCartUpdated();
        })
        .catch((error) => {
          errorReporter.error("Failed to add product to cart", {
            cartId: activeCart.id,
            ...errorReporter.getMetadata(error),
          });
          return dispatch(_fetchCart());
        });
    },
    true,
  );
}

export function removeProductFromCart(productData, removeAll = false) {
  const { planId, productOfferId } = productData;

  return _createAsyncAction("removeProductFromCart", (dispatch, getState) => {
    const state = getState();
    const activeCart = cartSelectors.activeCart(state);

    // If there's no active cart, don't take the action.
    if (!activeCart) return Promise.resolve();

    let cartProduct;
    if (planId) {
      // Get the existing cartProduct for the current planId.
      cartProduct = _getActiveCartProduct(state, { planId });
    }

    if (productOfferId) {
      cartProduct = cartProductSelectors.activeCartProductForProductOffer(
        state,
        productOfferId,
      );
    }

    // If there's no existing cartProduct, don't take the action.
    if (!cartProduct) return Promise.resolve();

    // if removeAll is false, only remove 1 quantity of product
    if (!removeAll && cartProduct.quantity > 1) {
      return dispatch(
        _updateCartProductQuantity(activeCart.id, cartProduct, -1),
      );
    }
    // Else, remove all quantities of product

    // Synchronously remove the cartProduct so that the change is immediately
    // visible to the user.
    dispatch(removeCartProduct(cartProduct.id));

    const commonProperties = { quantity: cartProduct.quantity };

    if (productOfferId) {
      const productOffer = getProductOffer(state, productOfferId);
      const productOfferProperties = {
        product_offer_id: productOfferId,
        product_offer_name: productOffer?.name,
        ...commonProperties,
      };

      trackBundleRemoved(productOfferId, productOfferProperties);
    } else {
      trackProductRemoved(planId, commonProperties);
    }

    // Issue the request to remove the product from the cart.
    return dispatch(_deleteCartProduct(activeCart.id, cartProduct.id))
      .then(async () => {
        const applyCode = state.applyCode;
        if (applyCode.code) {
          await dispatch(addPendingCode(applyCode.code));
        }

        // Track cart update
        trackCartUpdated();
      })
      .catch((error) => {
        errorReporter.error("Failed to remove product from cart", {
          cartId: activeCart.id,
          ...errorReporter.getMetadata(error),
        });
        return dispatch(_fetchCart());
      });
  });
}

export function updateCartProductQuantity(planId, quantity) {
  return _createAsyncAction(
    "updateCartProductQuantity",
    (dispatch, getState) => {
      const state = getState();
      const activeCart = cartSelectors.activeCart(state);

      // If there's no active cart, don't take the action.
      if (!activeCart) return Promise.resolve();

      // Get the existing cartProduct for the current planId.
      const cartProduct = _getActiveCartProduct(state, { planId });

      // If there's no existing cartProduct, don't take the action.
      if (!cartProduct) return Promise.resolve();

      // Change in product quantity
      const delta = quantity - cartProduct.quantity;

      return dispatch(
        _updateCartProductQuantity(activeCart.id, cartProduct, delta),
      );
    },
  );
}

/**
 * Adds multiple products to the cart. If the quantity of the products in the
 * cart + the quantity of the products being added exceeds the maximum number
 * of allowed products, the cart is cleared and the new products are added
 * instead. Otherwise the products are added to the existing cart.
 *
 * @param {Array<Object>} productData An array of objects with the keys
 *   { planId, quantity }.
 */
export function addProductsToCart(productData) {
  return _createAsyncAction("addProductsToCart", async (dispatch, getState) => {
    const state = getState();

    // If there's no active cart, don't take the action.
    const activeCart = cartSelectors.activeCart(state);

    if (!activeCart) return Promise.resolve();

    const productsToAddQuantity = productData.reduce((sum, data) => {
      sum += data.quantity;
      return sum;
    }, 0);

    const productLimit = getBundleCartLimit();
    const currentProductQuantity =
      cartProductSelectors.activeCartProductQuantity(state);

    if (productsToAddQuantity + currentProductQuantity > productLimit) {
      // If the total quantity of the existing cart + the new items exceeded
      // the product limit, clear the cart and add the items instead.
      return dispatch(_clearCartAndAddProducts(productData));
    } else {
      // If the new products fit in the cart, add them to the existing cart
      // instead.
      return dispatch(_addProductsToExistingCart(activeCart, productData));
    }
  });
}

/**
 * Clears the current cart and adds the new products instead. Synchronously
 * the entire cart is cleared and replaced with stub CartProducts representing
 * the new items. Asynchronously multiple fetch requests are made to DELETE the
 * existing products and POST the new Cart Products. If any of the requests
 * fail, the action is abandoned and the cart is re-fetched.
 *
 * @param {Array<Object>} productData An array of objects with the keys
 *   { planId, quantity }.
 */
export function clearCartAndAddProducts(
  productData,
  hideWipeoutBanner = false,
  propertiesToTrack = {},
) {
  return _createAsyncAction("clearCartAndAddProducts", (dispatch) =>
    dispatch(
      _clearCartAndAddProducts(
        productData,
        hideWipeoutBanner,
        propertiesToTrack,
      ),
    ),
  );
}

export function addCouponToCart(code) {
  return _createAsyncAction("addCouponToCart", async (dispatch, getState) => {
    const state = getState();

    // If there's no active cart, don't take the action.
    const activeCart = cartSelectors.activeCart(state);
    if (!activeCart) return;

    const payload = activeCart.serialize({
      discountCode: code,
    });

    return new Promise((resolve, reject) =>
      fetch(
        `carts/${activeCart.id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/vnd.api+json",
          },
          body: JSON.stringify(payload),
          credentials: "include",
        },
        dispatch,
        ModelType,
        undefined,
        undefined,
        0,
      )
        .then(() => {
          trackCouponAdded(code);
          resolve();
        })
        .catch((error) => {
          trackCouponDenied(code);
          reject(error);
        }),
    );
  });
}

export function addGiftCardToCart(code) {
  return _createAsyncAction("addGiftCardToCart", (dispatch, getState) => {
    const state = getState();

    // If there's no active cart, don't take the action.
    const activeCart = cartSelectors.activeCart(state);
    if (!activeCart) return Promise.resolve();

    const payload = activeCart.serialize({
      giftCardCodes: [...activeCart.giftCardCodes, code],
    });

    return fetch(
      `carts/${activeCart.id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/vnd.api+json",
        },
        body: JSON.stringify(payload),
        credentials: "include",
      },
      dispatch,
      ModelType,
      undefined,
      undefined,
      0,
    );
  });
}

export function removeGiftCardFromCart(code) {
  return _createAsyncAction("removeGiftCardFromCart", (dispatch, getState) => {
    const state = getState();

    // If there's no active cart, don't take the action.
    const activeCart = cartSelectors.activeCart(state);
    if (!activeCart) return Promise.resolve();

    const payload = activeCart.serialize({
      giftCardCodes: activeCart.giftCardCodes.filter((c) => c !== code),
    });

    return fetch(
      `carts/${activeCart.id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/vnd.api+json",
        },
        body: JSON.stringify(payload),
        credentials: "include",
      },
      dispatch,
      ModelType,
    );
  });
}

export function updateCartQueue(queue) {
  return {
    type: "UPDATE_CART_QUEUE",
    payload: {
      queue,
    },
  };
}

export function removeCouponFromCart() {
  return _createAsyncAction("removeCouponFromCart", (dispatch, getState) => {
    const state = getState();

    // If there's no active cart, don't take the action.
    const activeCart = cartSelectors.activeCart(state);
    if (!activeCart) return Promise.resolve();

    // If there's no activeCoupon, do not dispatch the action.
    const activeCoupon = couponSelectors.activeCoupon(state);
    if (!activeCoupon) return Promise.resolve();

    const { discountCode, discountAmount } = activeCart;

    const payload = activeCart.serialize({
      discountCode: undefined,
    });

    return fetch(
      `carts/${activeCart.id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/vnd.api+json",
        },
        body: JSON.stringify(payload),
        credentials: "include",
      },
      dispatch,
      ModelType,
    ).then(() => {
      trackCouponRemoved(discountCode, discountAmount);
    });
  });
}

export function updateShippingAddress(address) {
  return _createAsyncAction(
    "updateShippingAddress",
    (dispatch, getState) => {
      const state = getState();

      // If there's no active cart, don't take the action.
      const activeCart = cartSelectors.activeCart(state);
      if (!activeCart) return Promise.resolve();

      const payload = activeCart.serialize({
        shipping_address: {
          city: address.city,
          state: address.state,
          postal_code: address.postal_code,
          country: address.country,
        },
      });

      return fetch(
        `carts/${activeCart.id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/vnd.api+json",
          },
          body: JSON.stringify(payload),
          credentials: "include",
          mode: "cors",
        },
        dispatch,
        ModelType,
      );
    },
    true,
  );
}

export function addNormalizedCart(data, associations) {
  return (dispatch) => {
    const cart = new Cart();
    cart.deserialize(data);
    dispatch(addCart(cart, associations));
  };
}

export function addCart(data, associations) {
  return (dispatch, getState) => {
    return normalizedActions.updateOrCreateModel(
      dispatch,
      getState().carts,
      ModelType,
      data,
      associations,
    );
  };
}

export function updateReplaceBanner(shouldDisplay) {
  return {
    type: "UPDATE_CART_REPLACE_BANNER",
    payload: {
      shouldDisplay,
    },
  };
}

export function updateLimitBanner(shouldDisplay) {
  return {
    type: "UPDATE_CART_LIMIT_BANNER",
    payload: {
      shouldDisplay,
    },
  };
}

export function updateProductOfferLimitBanner(shouldDisplay) {
  return {
    type: "UPDATE_CART_PRODUCT_OFFER_LIMIT_BANNER",
    payload: {
      shouldDisplay,
    },
  };
}

export function updateWipeoutBanner(shouldDisplay) {
  return {
    type: "UPDATE_CART_WIPEOUT_BANNER",
    payload: {
      shouldDisplay,
    },
  };
}

export function updateFlyoutCart(shouldDisplay) {
  return {
    type: "UPDATE_FLYOUT_CART",
    payload: {
      shouldDisplay,
    },
  };
}

// Helper Actions

function _tryApplyPromotionIntent() {
  return async (dispatch) => {
    const queryParmas = new URLSearchParams(window?.location.search);
    const code = queryParmas.get("promo");

    if (!code) return;

    const body = JSON.stringify({ code, store_id: siteStore.storeId });

    try {
      const response = await fetchInternal("promotion_intents", {
        method: "POST",
        body,
        credentials: "include",
      });
      dispatch(addPromotionIntent(response.data));
    } catch (error) {
      errorReporter.error(
        "Failed to apply promotion intent",
        errorReporter.getMetadata(error),
      );
    }
  };
}

function _fetchCart() {
  return async (dispatch) => {
    dispatch(removeCartProducts());

    try {
      const params = { store_id: siteStore.storeId };
      await fetch(
        `carts/active?${queryString.stringify(params)}`,
        {
          credentials: "include",
        },
        dispatch,
        ModelType,
      );
      dispatch(_updateCartFetchFailed(false));
    } catch (error) {
      dispatch(_updateCartFetchFailed(true));
      errorReporter.error(
        "Failed to fetch cart",
        errorReporter.getMetadata(error),
      );
    }
  };
}

function _updateCartFetchFailed(fetchFailed) {
  return {
    type: "UPDATE_CART_FETCH_FAILED",
    payload: {
      fetchFailed,
    },
  };
}

function _updateCartProductQuantity(
  cartId,
  cartProduct,
  delta,
  propertiesToTrack,
) {
  return (dispatch) => {
    const currerntQuantity = cartProduct.quantity;
    const updatedQuantity = currerntQuantity + delta;

    const updatedCartProduct = cartProduct.cloneWithUpdates({
      quantity: updatedQuantity,
      itemQuantity: updatedQuantity,
    });

    // Add the updated cart product to the store. The existing product will
    // be overwritten, since the id is unchanged.
    dispatch(addCartProduct(updatedCartProduct));

    const planId = cartProduct.planId;
    if (delta > 0) {
      // Track the new product added.
      trackProductAdded(planId, { quantity: delta, ...propertiesToTrack });
    } else {
      // Track the product removed.
      trackProductRemoved(planId, { quantity: Math.abs(delta) });
    }

    return dispatch(_patchCartProduct(cartId, updatedCartProduct))
      .then(() => {
        dispatch(refreshBestEligiblePromotion());
      })
      .catch((error) => {
        errorReporter.error("Failed to update cart product quantity", {
          cartId,
          ...errorReporter.getMetadata(error),
        });
        return dispatch(_fetchCart());
      });
  };
}

/**
 * Clears the current cart and adds the new products instead. Synchronously
 * the entire cart is cleared and replaced with stub CartProducts representing
 * the new items. Asynchronously multiple fetch requests are made to DELETE the
 * existing products and POST the new Cart Products. If any of the requests
 * fail, the action is abandoned and the cart is re-fetched.
 *
 * @param {Array<Object>} productData An array of objects with the keys
 *   { planId, quantity }.
 */
export function _clearCartAndAddProducts(
  productData,
  hideWipeoutBanner = false,
  propertiesToTrack = {},
) {
  return async (dispatch, getState) => {
    const state = getState();

    // If there's no active cart, don't take the action.
    const activeCart = cartSelectors.activeCart(state);
    if (!activeCart) return Promise.resolve();

    productData.forEach((data) => {
      const { planId, quantity, productOfferId } = data;

      // Remove all Cart Products from the store that are associated with the
      // id of the active cart. We do not remove stub products, since the
      // stubs represent the new products that are being added and must
      // remain visible to the user.
      dispatch(
        removeCartProducts({
          cartId: activeCart.id,
          id: (id) => !id.includes("stub"),
        }),
      );

      // Create a new Cart Product stub for each of the products that are being
      // added to the cart so that the change is immediately visible to the
      // user.
      const cartProductStub = _createStubCartProduct(
        state,
        activeCart.id,
        { planId, productOfferId },
        quantity,
      );
      dispatch(addCartProduct(cartProductStub));

      const trackProduct = (planId, properties) =>
        trackProductAdded(planId, { ...properties, ...propertiesToTrack });

      if (productOfferId) {
        const productOffer = getProductOffer(state, productOfferId);

        const propertiesToTrack = {
          product_offer_id: productOfferId,
          product_offer_name: productOffer?.name,
        };
        trackBundleAdded(productOfferId, propertiesToTrack);
      } else {
        trackProduct(planId);
      }
    });

    // Notify the user that we've replaced all the items in their cart.
    if (!hideWipeoutBanner) {
      dispatch(updateWipeoutBanner(true));
    }

    const activeCartProducts = cartProductSelectors.activeCartProducts(state);

    try {
      for (let i = 0; i < activeCartProducts.length; i++) {
        const cartProduct = activeCartProducts[i];
        // Issue the DELETE request to the backend. The products are removed one
        // by one, with each iteration of this loop, as there's currently no
        // bulk deletion endpoint.
        await dispatch(
          _deleteCartProduct(
            activeCart.id,
            cartProduct.id,
            true /* suppressStoreUpdates */,
          ),
        );
      }
      for (let i = 0; i < productData.length; i++) {
        // Create the new products on the backend.
        const data = productData[i];
        await dispatch(
          _postCartProduct(
            activeCart.id,
            { planId: data.planId, productOfferId: data.productOfferId },
            data.quantity,
          ),
        );
      }
      dispatch(refreshBestEligiblePromotion());
    } catch (error) {
      errorReporter.error("Failed to clearCartAndAddProducts", {
        cartId: activeCart.id,
        ...errorReporter.getMetadata(error),
      });
      return dispatch(_fetchCart());
    }
  };
}

/**
 * Adds the specified products to the current cart. Synchronously stub
 * CartProducts are created for any new items, and existing items will have
 * their quantities updated. Asynchronously multiple fetch requests are made to
 * PATCH the existing products and POST the new ones. If any of the requests
 * fail, the action is abandoned and the cart is re-fetched.
 *
 * @param {Cart} activeCart The active Cart model.
 * @param {Array<Object>} productData An array of objects with the keys
 *   { planId, quantity }.
 */
function _addProductsToExistingCart(activeCart, productData) {
  return async (dispatch, getState) => {
    const state = getState();

    const productsToCreate = [];
    const productsToUpdate = [];

    productData.forEach((data) => {
      const { planId, quantity } = data;

      // Get the existing cartProduct for the planId.
      const cartProduct = _getActiveCartProduct(state, { planId });

      // If the Cart Product for the planId currently exists in the store,
      // update its quantity instead of the creating a new stub product.
      if (cartProduct) {
        const currentQuantity = cartProduct.quantity;
        const updatedCartProduct = cartProduct.cloneWithUpdates({
          quantity: currentQuantity + quantity,
          itemQuantity: currentQuantity + quantity,
        });
        dispatch(addCartProduct(updatedCartProduct));
        trackProductAdded(planId, { quantity });
        productsToUpdate.push(updatedCartProduct);
      } else {
        // If the product does not exist, create a stub instead.
        const cartProductStub = _createStubCartProduct(
          state,
          activeCart.id,
          { planId },
          quantity,
        );
        dispatch(addCartProduct(cartProductStub));
        trackProductAdded(planId, { quantity });
        productsToCreate.push(data);
      }
    });

    try {
      for (let i = 0; i < productsToUpdate.length; i++) {
        // If this is last product to update and there are no products to
        // create, we know this is the last request.
        const isLastRequest =
          i === productsToUpdate.length - 1 && !productsToCreate.length;

        // PATCH the quantity update any existing items.
        const updateCartProduct = productsToUpdate[i];
        await dispatch(
          _patchCartProduct(
            activeCart.id,
            updateCartProduct,
            !isLastRequest /* suppressStoreUpdates */,
          ),
        );
      }
      for (let i = 0; i < productsToCreate.length; i++) {
        // POST the creation of any new products.
        const productData = productsToCreate[i];
        await dispatch(
          _postCartProduct(
            activeCart.id,
            { planId: productData.planId },
            productData.quantity,
          ),
        );
      }
      dispatch(refreshBestEligiblePromotion());
    } catch (error) {
      errorReporter.error("Failed to addProductsToExistingCart", {
        cartId: activeCart.id,
        ...errorReporter.getMetadata(error),
      });
      return dispatch(_fetchCart());
    }
  };
}

function _postCartProduct(cartId, productData, quantity = 1) {
  const { planId, productOfferId } = productData;

  return (dispatch, getState) => {
    const state = getState();

    let payload, product;
    if (planId) {
      product = planSelectors.productForPlanId(state, { id: planId });
      payload = {
        plan_id: planId,
        product_id: product.id,
        quantity: quantity,
      };
    }

    if (productOfferId) {
      payload = {
        product_offer_id: productOfferId,
        quantity: quantity,
      };
    }
    const cartProductAssociations = {
      ...(planId && { planId: planId, productId: product.id }),
      ...(productOfferId && { productOfferId: productOfferId }),
    };

    return fetch(
      `carts/${cartId}/cart_product`,
      {
        method: "POST",
        body: JSON.stringify(payload),
        credentials: "include",
      },
      dispatch,
      ModelType,
      // Pass the planId and productId associations. This ensures that the
      // new CartProduct created on the backend will be tied o the stub above.
      {
        cart_products: cartProductAssociations,
      },
    );
  };
}

function _patchCartProduct(cartId, updatedCartProduct, suppressStoreUpdates) {
  return (dispatch) => {
    const payload = updatedCartProduct.serialize();

    return fetch(
      `carts/${cartId}/cart_product/${updatedCartProduct.id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/vnd.api+json",
        },
        body: JSON.stringify(payload),
        credentials: "include",
      },
      dispatch,
      ModelType,
      undefined /* associations */,
      suppressStoreUpdates,
    );
  };
}

function _deleteCartProduct(
  cartId,
  cartProductId,
  suppressStoreUpdates = false,
) {
  return (dispatch) => {
    return fetch(
      `carts/${cartId}/cart_product/${cartProductId}`,
      {
        method: "DELETE",
        credentials: "include",
      },
      dispatch,
      ModelType,
      undefined /* associations */,
      suppressStoreUpdates,
    );
  };
}

// Helpers

function _createAsyncAction(actionName, action, bypassProcessing) {
  return async (dispatch, getState) => {
    const state = getState();
    const activeCart = cartSelectors.activeCart(state);
    const waitForProcessing = activeCart ? true : !bypassProcessing;

    if (cartSelectors.isProcessing(getState()) && waitForProcessing) {
      errorReporter.warn(`Attempted to ${actionName} while processing`);
      return Promise.resolve();
    }

    dispatch({ type: "CART_ACTION_START" });
    return action(dispatch, getState).finally(() => {
      dispatch({ type: "CART_ACTION_END" });
    });
  };
}

function getProductOffer(state, productOfferId) {
  const productOffer = productOfferForId(state, productOfferId);
  if (!productOffer) {
    throw new Error("No product offer found!");
  }
  return productOffer;
}

/**
 * Create a stub cartProduct and add it to the cart so that the addition is
 * immediately visible to the user.
 */
function _createStubCartProduct(
  state,
  cartId,
  { planId, productOfferId },
  quantity = 1,
) {
  const stubCartProduct = new CartProduct();
  stubCartProduct.quantity = quantity;
  stubCartProduct.itemQuantity = quantity;
  stubCartProduct.cartId = cartId;

  if (planId) {
    const plan = planSelectors.planForId(state, { id: planId });
    const product = planSelectors.productForPlanId(state, { id: planId });

    // If there's no plan or matching product, don't take the action.
    if (!plan || !product) {
      throw new Error("No plan or product found!");
    }

    stubCartProduct.planId = plan.id;
    stubCartProduct.productId = product.id;
    stubCartProduct.productPrice = plan.amount;
  }

  if (productOfferId) {
    const productOffer = getProductOffer(state, productOfferId);
    stubCartProduct.productOfferId = productOfferId;
    stubCartProduct.productPrice = productOffer.initialAmount(state);
  }

  return stubCartProduct;
}

/**
 *
 * Retrieve all cartProducts that match the specified planId. There should only
 * ever be 0 or 1 products matching this id.
 */
function _getActiveCartProduct(state, productData) {
  const { planId, productOfferId } = productData;

  if (productOfferId) {
    return cartProductSelectors.activeCartProductForProductOffer(
      state,
      productOfferId,
    );
  }

  const cartProducts = cartProductSelectors.activeCartProductsForPlan(state, {
    planId,
  });

  if (cartProducts.length > 1) {
    // TODO: Clear cart on error.
    throw new Error(`Multiple products found for planId: ${planId}!`);
  }
  return cartProducts[0];
}

import castle from "../services/castle";

const API_URL = process.env.GATSBY_RITUAL_API_URL + `/api/v1`;

class FetchInstance {
  constructor() {
    this.accessToken = "";
  }

  async fetchInternal(url, options = {}, retryCount = 0, retryInterval = 5000) {
    await this._updateHeaders(options);

    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/${url}`, options)
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              resolve();
            } else {
              response.json().then((data) => {
                resolve(data);
              });
            }
            return;
          }

          const status = response.status;
          if (!retryCount || this._isClientError(status)) {
            // If we should no longer retry the request, or we encounter a 4XX
            // error, reject with the response data. If the data cannt be
            // parsed, reject with the response.
            try {
              response
                .json()
                .then((data) =>
                  reject({
                    status: response.status,
                    ...data,
                  }),
                )
                .catch(() => {
                  reject(response);
                });
            } catch (e) {
              reject(response);
            }
          } else if (this._isServerError(status)) {
            // If we encounter a 5XX error and are still retrying, re-attempt the
            // fetch after with some delay.
            setTimeout(() => {
              this.fetchInternal(url, options, retryCount - 1, retryInterval)
                .then((data) => resolve(data))
                .catch((error) => reject(error));
            }, retryInterval);
          } else {
            // Reject with the response data. If the data cannt be parsed,
            // reject with the response.
            try {
              response.json().then((data) =>
                reject({
                  status: response.status,
                  ...data,
                }),
              );
            } catch (e) {
              reject(response);
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  setAccessToken(accessToken) {
    this.accessToken = accessToken;
  }

  async _updateHeaders(options = {}) {
    const headers = {
      "Content-Type": "application/json",
    };

    if (this.accessToken) {
      headers["Authorization"] = `Bearer ${this.accessToken}`;
    }

    if (options.fetchOptions?.addCastleRequestToken) {
      const requestToken = await castle.createRequestToken();
      headers["X-Castle-Request-Token"] = requestToken;
      delete options.fetchOptions.addCastleRequestToken;
    }

    options.headers = { ...headers, ...options.headers };
  }

  _isClientError(status) {
    return status >= 400 && status < 500;
  }

  _isServerError(status) {
    return status >= 500;
  }
}

let fetchInstance = new FetchInstance();

export function setAccessToken(accessToken) {
  fetchInstance.setAccessToken(accessToken);
}

export function reset() {
  fetchInstance = new FetchInstance();
}

export default function fetchInternal(url, options, retryCount, retryInterval) {
  return fetchInstance.fetchInternal(url, options, retryCount, retryInterval);
}

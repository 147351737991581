import { createSelector } from "reselect";
import { BillingType } from "../../constants/plan";
import siteStore from "../../services/siteStore";
import productSelectors from "../product/selectors";

const _byId = (state) => state.plans.byId;
const _allIds = (state) => state.plans.allIds;

const allPlans = createSelector(_byId, _allIds, (byId, allIds) =>
  allIds.map((id) => byId[id]),
);

const allPlansByCurrentStore = createSelector(
  [allPlans, (state) => state.i18n && state.i18n.locale],
  (allPlans) =>
    allPlans.filter(
      (plan) => !plan.storeId || plan.storeId === siteStore.storeId,
    ),
);

const planForId = createSelector(
  allPlansByCurrentStore,
  (_, props) => props.id,
  (allPlans, id) => allPlans.find((plan) => plan.id === id),
);

const planForProductSku = createSelector(
  (state, props) => productSelectors.productForSku(state, { sku: props.sku }),
  allPlansByCurrentStore,
  (_, props) => props.type,
  (product, allPlans, type = BillingType.Recurring) => {
    if (!product) return null;
    return allPlans.find(
      (plan) =>
        plan.productId === product.id &&
        plan.billingType === type &&
        plan.soldIndividually &&
        plan.intervalCount === 30,
    );
  },
);

const allPlansForProductSku = createSelector(
  (state, props) => productSelectors.productForSku(state, { sku: props.sku }),
  allPlansByCurrentStore,
  (product, allPlans) => {
    if (!product) return null;
    return allPlans.filter((plan) => plan.productId === product.id && plan.soldIndividually);
  },
);

const productForPlanId = createSelector(
  planForId,
  (state) => productSelectors.allProducts(state),
  (plan, allProducts) => {
    if (!plan) return null;
    return allProducts.find((product) => product.id === plan.productId);
  },
);

const exports = {
  allPlans,
  allPlansByCurrentStore,
  planForId,
  planForProductSku,
  allPlansForProductSku,
  productForPlanId,
};

export default exports;

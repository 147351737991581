// Utils
import {
  getProductAttributes,
  getPlanIdForProductSku,
  getAllPlansForProductSku,
} from "../planToProduct";
import cartProductSelectors from "../../store/cart-product/selectors";
import { productOfferForId } from "../../store/product-offer/selectors";

export function getPropertiesForProductSku(sku, quantity = 1) {
  const planId = getPlanIdForAnyBillingType(sku);
  return getPropertiesForPlanId(planId, quantity);
}

export function getPropertiesForPlanId(planId, quantity = 1) {
  if (!planId) return {};
  const productAttributes = getProductAttributes(planId, quantity);
  return formatProductAttributes(productAttributes);
}

export function getPropertiesForProductOfferId(state, productOfferId) {
  const productOffer = productOfferForId(state, productOfferId);
  const initialPlanIds = productOffer.initialPlanIds(state);

  return initialPlanIds.map((planId) => {
    const productAttributes = getProductAttributes(planId);
    return formatProductAttributes(productAttributes);
  });
}

export function getCartProductsForIterable(state) {
  return cartProductSelectors.activeCartProducts(state).flatMap((product) => {
    const { quantity, planId, productOfferId } = product;

    const processProduct = (planId) => {
      const productAttributes = getProductAttributes(planId, quantity);
      const { slug, ...attributesWithoutSlug } = productAttributes;

      return attributesWithoutSlug;
    };

    if (productOfferId) {
      const productOffer = productOfferForId(state, productOfferId);
      return productOffer.initialPlanIds(state).map(processProduct);
    }

    return [processProduct(planId)];
  });
}

/* helper methods */

// return include a la carte if it is the only plan for the product
function getPlanIdForAnyBillingType(sku, quantity = 1) {
  let plans = getAllPlansForProductSku(sku); // get all plans
  let selectedPlan = plans.find((plan) => plan.billingType === "one_time");
  let isOneTimePlanOnly = plans.length === 1 && selectedPlan !== undefined;

  return isOneTimePlanOnly ? selectedPlan.id : getPlanIdForProductSku(sku);
}

function formatProductAttributes(productAttributes) {
  if (!productAttributes) return {};

  const product_id = productAttributes.id;
  delete productAttributes.slug;
  delete productAttributes.id;

  return {
    product_id,
    ...productAttributes,
  };
}

import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import app from "./app/reducer";
import applyCode from "./apply-code/reducer";
import banners from "./banners/reducer";
import i18n from "./i18n/reducer";
import navigation from "./navigation/reducer";
import outOfStock from "./out-of-stock/reducer";
import pendingCode from "./pending-code/reducer";
import route from "./route/reducer";
import staticData from "./staticData/reducer";

// Normalized Store Reducers
import carts from "./cart/reducer";
import cartProducts from "./cart-product/reducer";
import coupons from "./coupon/reducer";
import promotion from "./promotion/reducer";
import promotionPlan from "./promotion-plan/reducer";
import guests from "./guest/reducer";
import plans from "./plan/reducer";
import products from "./product/reducer";
import productGroups from "./product-group/reducer";
import shippingOptions from "./shipping-option/reducer";
import subscriptions from "./subscription/reducer";
import subscriptionItems from "./subscription-item/reducer";
import invoiceItems from "./invoice-item/reducer";
import users from "./user/reducer";
import promotionIntents from "./promotion-intent/reducer";
import productOffers from "./product-offer/reducer";
import productOfferPlans from "./product-offer-plan/reducer";

const rootReducer = combineReducers({
  app,
  applyCode,
  banners,
  i18n,
  navigation,
  outOfStock,
  pendingCode,
  route,
  staticData,
  // Normalized Stores
  carts,
  cartProducts,
  coupons,
  promotion,
  promotionPlan,
  guests,
  invoiceItems,
  plans,
  products,
  productGroups,
  shippingOptions,
  subscriptions,
  subscriptionItems,
  users,
  promotionIntents,
  productOffers,
  productOfferPlans,
});

export function create(initialState = {}) {
  const composeEnhancers =
    typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        })
      : compose;

  const enhancer = composeEnhancers(
    applyMiddleware(thunk),
    // other store enhancers if any
  );
  return createStore(rootReducer, initialState, enhancer);
}

const store = create({});

export function getStore() {
  return store;
}

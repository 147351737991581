import { useSelector } from "react-redux";
import planSelectors from "../store/plan/selectors";
import { selectPromotionPlanByPromotionAndPlanId } from "../store/promotion-plan/selectors";

const usePromotionDiscount = (promotion, planIdOrSku) =>
  useSelector((state) => {
    const isRefArray = Array.isArray(planIdOrSku);
    const refToPlans = isRefArray ? planIdOrSku : [planIdOrSku];

    const promotionDiscounts = refToPlans.map((refToPlan) => {
      const promotionPlanFromPlanId =
        promotion &&
        selectPromotionPlanByPromotionAndPlanId(state, promotion, refToPlan);

      const plan = planSelectors.planForProductSku(state, { sku: refToPlan });
      const promotionPlanFromSku =
        plan?.id &&
        promotion &&
        selectPromotionPlanByPromotionAndPlanId(state, promotion, plan.id);

      return promotionPlanFromPlanId || promotionPlanFromSku || promotion;
    });

    return isRefArray ? promotionDiscounts : promotionDiscounts[0];
  });

export default usePromotionDiscount;

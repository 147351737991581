// Actions
import { addProductToCart, fetchCart, updateCartQueue } from "../cart/actions";
import cartSelectors from "../cart/selectors";
import { fetchGuest } from "../guest/actions";
import { setInitialOutOfStockState } from "../out-of-stock/actions";
import { fetchActiveSubscriptions } from "../subscription/actions";

// Selectors
import userSelectors from "../user/selectors";

// Observers
import applyCodeObserver from "../apply-code/observer";
import pendingCodeObserver from "../pending-code/observer";

// Utils
import launchDarkly from "../../services/launchDarkly";
import { analytics } from "../../utils/analytics";
import { getBundleCartLimit } from "../../utils/bundle";
import { removeCookie } from "../../utils/cookies";
import {
  authAndFetchUser,
  hasLoggedOutUser,
  identifyUser,
} from "../../utils/currentUser";
import { lux } from "../../utils/lux";
import {
  fetchPromotions,
  refreshBestEligiblePromotion,
} from "../promotion/actions";

export function appLoaded() {
  return (dispatch) => {
    // Initialize any client state.
    dispatch(setInitialOutOfStockState());

    // Start the applyCode and pendingCode observers.
    applyCodeObserver.start();
    pendingCodeObserver.start();

    // Clear the current guest-id cookie if the user has logged out.
    if (hasLoggedOutUser()) {
      removeCookie("guest_id", {
        domain: process.env.GATSBY_COOKIE_DOMAIN,
      });
    }

    const fetchGuestPromise = dispatch(fetchGuest(analytics.anonymousId));

    _loadSegmentUser();

    // Wait for the guest and the current user (if any) to be authed before
    // marking initial authorization as complete.
    Promise.all([fetchGuestPromise, authAndFetchUser()]).then(() => {
      dispatch(authInitialized());
    });

    dispatch({
      type: "APP_LOADED",
    });
  };
}

export function authInitialized() {
  return (dispatch) => {
    const fetchDataAfterAuthPromise = dispatch(fetchDataAfterAuth());
    dispatch({
      type: "AUTH_INITIALIZED",
    });
    return fetchDataAfterAuthPromise;
  };
}

export function fetchDataAfterAuth() {
  return (dispatch, getState) => {
    dispatch({
      type: "FETCH_AFTER_AUTH_START",
    });

    const productLimit = getBundleCartLimit();

    // When the auth is updated, we should re-fetch the cart and subscription
    // data.
    const fetchSubscriptionsPromise =
      productLimit > 1
        ? dispatch(_fetchActiveSubscriptions())
        : Promise.resolve();
    const fetchCartPromise = dispatch(fetchCart()).then(() => {
      // If products were added to the cart before it loaded,
      // run through the queue
      const queue = [...cartSelectors.queue(getState())];
      dispatch(updateCartQueue([]));
      queue.forEach((item) => {
        dispatch(
          addProductToCart({ planId: item.planId }, item.propertiesToTrack),
        );
      });

      // Once segment is loaded, we should identify the user. We wait until
      // after the cart is fetched to ensure that the cartId is included in
      // the identify call.
      identifyUser();
    });

    // Fetch all active promotions and promotion_plans
    const fetchPromotionsPromise = dispatch(fetchPromotions());

    return Promise.all([
      fetchCartPromise,
      fetchSubscriptionsPromise,
      fetchPromotionsPromise,
    ])
      .then(() => {
        dispatch(refreshBestEligiblePromotion());
        dispatch({
          type: "FETCH_AFTER_AUTH_SUCCESS",
        });
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_AFTER_AUTH_FAILURE",
        });
      });
  };
}

function _fetchActiveSubscriptions() {
  return (dispatch, getState) => {
    // If the user is not logged in, don't fetch the subscriptions.
    if (!userSelectors.isLoggedIn(getState())) return Promise.resolve();

    return dispatch(fetchActiveSubscriptions());
  };
}

function _loadSegmentUser() {
  return analytics.waitForUserInfo().then((userInfo) => {
    console.log("Segment user loaded", userInfo);

    // Once the user is loaded, identify the user with LaunchDarkly.
    launchDarkly.identify();

    lux.setLuxDataFromUser(userInfo);
    return userInfo;
  });
}

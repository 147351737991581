import { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Font } from "../../utils/style";

import metrics from "../../utils/metrics";
import fetchInternal from "../../utils/fetch";

import {
  useStripe,
  useElements,
  ExpressCheckoutElement,
} from "@stripe/react-stripe-js";

import useVariation from "../../hooks/useVariation";

import { getProductAttributes } from "../../utils/planToProduct";
import { getStore } from "../../store/createStore";
import i18nSelectors from "../../store/i18n/selectors";
import planSelectors from "../../store/plan/selectors";
import cartProductSelectors from "../../store/cart-product/selectors";
import { productOfferForId } from "../../store/product-offer/selectors";

import { isEmpty } from "lodash";
import { sha256 } from "js-sha256";
import { trackCheckoutStarted } from "../../utils/tracking/cart";

import Smarty from "../../services/smarty";

const ExpressCheckoutContainer = styled.div`
  padding: var(--between-components-only-spacing-075, 12px) 0;
`;

const ExpressCheckoutSeparator = styled.div`
  color: var(--solid-primary-indigo-blue-80, #43558c);
  text-align: center;
  ${Font.circular};
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 140% */
  letter-spacing: 0.8px;
  text-transform: uppercase;
  position: relative;

  &:before {
    position: absolute;
    content: "";
    background-color: var(--solid-neutral-cool-60, #dbe2e6);
    height: 1px;
    width: calc(50% - 64px);
    right: calc(50% + 64px);
    top: calc(50% + 0.5px);
  }

  &:after {
    position: absolute;
    content: "";
    background-color: var(--solid-neutral-cool-60, #dbe2e6);
    height: 1px;
    width: calc(50% - 64px);
    left: calc(50% + 64px);
    top: calc(50% + 0.5px);
  }
`;

const ExpressCheckoutBottomSeparator = styled(ExpressCheckoutSeparator)`
  &:before {
    width: calc(50% - 16px);
    right: calc(50% + 16px);
  }

  &:after {
    width: calc(50% - 16px);
    left: calc(50% + 16px);
  }
`;

let LDValidateShippingAddress;

const StripeExpressCheckout = ({ cart, cartProducts, coupon }) => {
  const stripe = useStripe();
  const elements = useElements();
  const store = getStore();
  const recurringCartProducts = useSelector(
    cartProductSelectors.activeCartRecurringItems,
  );

  const [, /*errorMessage*/ setErrorMessage] = useState();
  const [display, setDisplay] = useState("none");

  const expressCheckoutOptions = {
    buttonHeight: 48,
    paymentMethodOrder: ["apple_pay", "google_pay", "link"],
    wallets: {
      applePay: "never",
      googlePay: "never",
    },
  };

  const launchDarklyOptions = useVariation("stripe-express-checkout");
  LDValidateShippingAddress = useVariation(
    "express-checkout-validate-shipping-address",
  );

  if (Object.keys(launchDarklyOptions).length === 0) {
    // If there are no options, don't render the express checkout element.
    return null;
  }

  if (launchDarklyOptions.all) {
    expressCheckoutOptions.wallets = {
      applePay: "auto",
      googlePay: "auto",
    };
  }

  if (launchDarklyOptions.apple_pay) {
    expressCheckoutOptions.wallets.applePay = "auto";
  }

  if (launchDarklyOptions.google_pay) {
    expressCheckoutOptions.wallets.googlePay = "auto";
  }

  const updateElements = () => {
    elements.update({
      mode: "subscription",
      amount: cart.total || 100,
      currency: cart.currency,
      setupFutureUsage: "off_session",
    });
  };

  if (elements && cart && isEmpty(cartProducts) === false) {
    updateElements();
  }

  const onReady = ({ availablePaymentMethods }) => {
    if (availablePaymentMethods) {
      if (Object.keys(launchDarklyOptions).length === 0) {
        return;
      }
      if (
        (launchDarklyOptions.all || launchDarklyOptions.google_pay) &&
        availablePaymentMethods.googlePay
      ) {
        setDisplay("block");
      } else if (
        (launchDarklyOptions.all || launchDarklyOptions.apple_pay) &&
        availablePaymentMethods.applePay
      ) {
        setDisplay("block");
      }
    }
  };

  const onClick = ({ resolve }) => {
    metrics.track("CTA Clicked", {
      title: "Express Checkout Payment Option",
      location: "Cart",
    });

    trackCheckoutStarted();

    let lineItems = cartProducts.map((cartProduct) => {
      const { quantity, planId, productOfferId, productPrice } = cartProduct;
      let name = "";

      if (productOfferId) {
        const productOffer = productOfferForId(
          store.getState(),
          productOfferId,
        );
        name = productOffer.name;
      } else {
        let product = getProductAttributes(planId);
        name = product.name;
      }

      return {
        name: `${quantity}x ${name}`,
        amount: productPrice * quantity,
      };
    });

    if (cart.discountAmount > 0) {
      lineItems.push({
        name: "Discount",
        amount: -cart.discountAmount,
      });
    }

    lineItems.push({
      name: "Tax",
      amount: cart.totalTax || 0,
    });

    // if cart.shippingAmount is not null, add shipping line item
    if (cart.shippingAmount > 0) {
      lineItems.push({
        name: "Shipping",
        amount: cart.shippingAmount,
      });
    }

    let recurringTotal = recurringCartProducts.reduce((total, cartProduct) => {
      if (cartProduct.productOfferId) {
        const productOffer = productOfferForId(
          store.getState(),
          cartProduct.productOfferId,
        );
        return (
          total +
          productOffer.futureAmount(store.getState()) * cartProduct.quantity
        );
      }
      const plan = planSelectors.planForId(store.getState(), {
        id: cartProduct.planId,
      });

      return total + plan.amount * cartProduct.quantity;
    }, 0);

    let activeStore = i18nSelectors.activeStore(store.getState());

    const options = {
      emailRequired: true,
      phoneNumberRequired: false,
      shippingAddressRequired: true,
      allowedShippingCountries: activeStore.countries,
      lineItems: lineItems,
      shippingRates: [
        // TODO: Update to be dynamic.
        {
          id: "free-shipping",
          displayName: "Free shipping",
          amount: 0,
          deliveryEstimate: {
            maximum: { unit: "day", value: 6 }, // TODO: Replace with cart shipping estimate.
            minimum: { unit: "day", value: 4 }, // TODO: Replace with cart shipping estimate.
          },
        },
      ],
    };

    // If there are recurring products in the cart, add the terms for Apple Pay Recurring Billing
    if (recurringCartProducts.length > 0) {
      options.applePay = {
        recurringPaymentRequest: {
          paymentDescription: "My Ritual Subscription",
          managementURL: "https://account.ritual.com/",
          regularBilling: {
            amount: recurringTotal,
            label: "Recurring subscription (plus tax)",
            recurringPaymentIntervalUnit: "day",
            recurringPaymentIntervalCount: 30,
            recurringPaymentStartDate: new Date(
              new Date().setDate(new Date().getDate() + 30),
            ),
          },
          billingAgreement:
            "Your subscription will renew monthly and you will be charged the monthly subscription fees until you cancel. You can cancel anytime by visiting your account page on our website. By clicking Pay, you agree that you have read, understand, and agree to be bound by Natals, Inc.'s Terms of Service, including the billing and payment terms set forth therein.",
        },
      };
    }

    sessionStorage.setItem("rit-purchased_cart", JSON.stringify(cart));
    sessionStorage.setItem(
      "rit-purchased_cart_products",
      JSON.stringify(cartProducts),
    );

    updateElements();

    resolve(options);
  };

  const onCancel = () => {
    updateElements();

    setErrorMessage(null);
    sessionStorage.removeItem("rit-cart_id");
    sessionStorage.removeItem("rit-payment_intent_client_secret");
    sessionStorage.removeItem("rit-order_number");
    sessionStorage.removeItem("rit-reset_password_token");
    sessionStorage.removeItem("rit-subscription_id");
    sessionStorage.removeItem("rit-express_checkout_confirm_event");
    sessionStorage.removeItem("rit-purchased_cart");
    sessionStorage.removeItem("rit-purchased_cart_products");

    metrics.track("Payment Info Cancelled", {
      payment_element: "Express Checkout",
    });
  };

  const onShippingAddressChange = async ({ resolve, address }) => {
    let payload = {
      shipping_address: {
        city: address.city,
        state: address.state,
        postal_code: address.postal_code,
        country: address.country,
      },
    };
    if (coupon) {
      payload.discount_code = coupon.code;
    }

    const response = await fetchInternal(`carts/${cart.id}`, {
      method: "PATCH",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      fetchOptions: {
        addCastleRequestToken: true,
      },
      body: JSON.stringify(payload),
    });

    let updatedLineItems = cartProducts.map((cartProduct) => {
      const { quantity, planId, productOfferId, productPrice } = cartProduct;
      let name = "";

      if (productOfferId) {
        const productOffer = productOfferForId(
          store.getState(),
          productOfferId,
        );
        name = productOffer.name;
      } else {
        let product = getProductAttributes(planId);
        name = product.name;
      }

      return {
        name: `${quantity}x ${name}`,
        amount: productPrice * quantity,
      };
    });

    if (response.data.attributes.discount_amount > 0) {
      updatedLineItems.push({
        name: "Discount",
        amount: -response.data.attributes.discount_amount,
      });
    }

    if (response.data.attributes.tax_inclusive === false) {
      updatedLineItems.push({
        name: "Tax",
        amount: response.data.attributes.total_tax,
      });
    }

    if (response.data.attributes.shipping_amount > 0) {
      updatedLineItems.push({
        name: "Shipping",
        amount: response.data.attributes.shipping_amount,
      });
    }

    sessionStorage.setItem(
      "rit-purchased_cart",
      JSON.stringify(response.data.attributes),
    );

    elements.update({
      amount: response.data.attributes.total,
      currency: response.data.attributes.currency,
    });

    resolve({
      lineItems: updatedLineItems,
    });
  };

  const onConfirm = async (event) => {
    if (!stripe) {
      // Stripe.js hasn't loaded yet.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    metrics.track("Payment Info Entered", {
      payment_method: event.expressPaymentType,
      payment_element: "Express Checkout",
    });

    let shippingAddress = event.shippingAddress.address;

    if (LDValidateShippingAddress) {
      // Validate the shipping address with Smarty
      const smarty = new Smarty();
      const validatedAddress = await smarty.validateAddress({
        line1: event.shippingAddress.address.line1,
        line2: event.shippingAddress.address.line2,
        city: event.shippingAddress.address.city,
        state: event.shippingAddress.address.state,
        postal_code: event.shippingAddress.address.postal_code,
        country: event.shippingAddress.address.country,
      });
      if (!validatedAddress) {
        return;
      }
      shippingAddress = validatedAddress;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      console.log("submitError", submitError);
      setErrorMessage(submitError.message);
      return;
    }

    // Store the event and cart in session storage so we can access it on the confirmation page.
    sessionStorage.setItem(
      "rit-express_checkout_confirm_event",
      JSON.stringify(event),
    );

    let paymentIntentClientSecret = sessionStorage.getItem(
      "rit-payment_intent_client_secret",
    );
    let orderNumber = sessionStorage.getItem("rit-order_number");

    if (!paymentIntentClientSecret) {
      const response = await fetchInternal("express_checkout/purchase", {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        fetchOptions: {
          addCastleRequestToken: true,
        },
        body: JSON.stringify({
          cart_id: cart.id,
          billing_address: {
            name: event.billingDetails.name,
            email: event.billingDetails.email,
            line1: event.billingDetails.address.line1,
            line2: event.billingDetails.address.line2,
            city: event.billingDetails.address.city,
            state: event.billingDetails.address.state,
            postal_code: event.billingDetails.address.postal_code,
            country: event.billingDetails.address.country,
          },
          shipping_address: {
            name: event.shippingAddress.name,
            line1: shippingAddress.line1,
            line2: shippingAddress.line2,
            city: shippingAddress.city,
            state: shippingAddress.state,
            postal_code: shippingAddress.postal_code,
            country: shippingAddress.country,
          },
        }),
      });

      const {
        user_id,
        first_name,
        last_name,
        client_secret,
        reset_password_token,
        order_number,
        subscription_id,
      } = response;

      if (!client_secret) {
        setErrorMessage("Error creating payment intent");
        return;
      }

      paymentIntentClientSecret = client_secret;
      sessionStorage.setItem("rit-payment_intent_client_secret", client_secret);

      orderNumber = order_number;
      sessionStorage.setItem("rit-order_number", order_number);

      metrics.identify(user_id, {
        first_name: first_name,
        last_name: last_name,
        email: event.billingDetails.email,
      });

      if (reset_password_token) {
        sessionStorage.setItem(
          "rit-reset_password_token",
          reset_password_token,
        );

        metrics.track("User Signup", {
          method: "provider:ritual",
          userHashedEmail: sha256(event.billingDetails.email),
        });
      }
      if (subscription_id) {
        sessionStorage.setItem("rit-subscription_id", subscription_id);
      }

      sessionStorage.setItem("rit-checkout", "express");
    }

    sessionStorage.setItem("rit-cart_id", cart.id);

    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret: paymentIntentClientSecret,
      confirmParams: {
        return_url: `${process.env.GATSBY_URL}/checkout/confirmation?cart_id=${cart.id}&order_number=${orderNumber}`,
      },
    });

    if (error) {
      // This point is only reached if there's an immediate error when
      // confirming the payment. Show the error to your customer (for example, payment details incomplete)
      console.log("payment error", error);
      setErrorMessage(error.message);
    } else {
      // The payment UI automatically closes with a success animation.
      // Your customer is redirected to your `return_url`.
    }
  };

  return (
    <div style={{ display }}>
      <ExpressCheckoutSeparator>Express checkout</ExpressCheckoutSeparator>
      <ExpressCheckoutContainer id="express-checkout-element">
        <ExpressCheckoutElement
          options={expressCheckoutOptions}
          onReady={onReady}
          onConfirm={onConfirm}
          onClick={onClick}
          onShippingAddressChange={onShippingAddressChange}
          onCancel={onCancel}
        />
        {/* {errorMessage && <div>{errorMessage}</div>} */}
      </ExpressCheckoutContainer>
      <ExpressCheckoutBottomSeparator>or</ExpressCheckoutBottomSeparator>
    </div>
  );
};

export default StripeExpressCheckout;

import { createSelector } from "reselect";

const _byId = (state) => (state.promotionPlan ? state.promotionPlan.byId : {});

export const selectPromotionPlanByPromotionAndPlanId = createSelector(
  _byId,
  (_, promotion) => promotion,
  (_, __, planId) => planId,
  (byId, promotion, planId) => {
    const promotionPlanId = promotion.promotionPlanIds.find(
      (promotionPlanId) => {
        const promotionPlan = byId[promotionPlanId];
        return promotionPlan?.planId === planId;
      },
    );
    return byId[promotionPlanId];
  },
);

import PromotionPlan, { ModelType } from "./model";
import normalizedActions from "../utils/normalized/actions";

export function addPromotionPlansFromGraphQL(promotionPlanData) {
  return (dispatch) => {
    promotionPlanData.forEach((data) => {
      const promotionPlan = new PromotionPlan();
      promotionPlan.deserialize(data);
      dispatch(addPromotionPlan(promotionPlan));
    });
  };
}

export function addPromotionPlanFromNormalizedData(data, associations) {
  return (dispatch) => {
    const promotionPlan = new PromotionPlan();
    promotionPlan.deserialize(data, associations);
    dispatch(addPromotionPlan(promotionPlan));
  };
}

export function addPromotionPlan(data, associations) {
  return (dispatch, getState) => {
    return normalizedActions.updateOrCreateModel(
      dispatch,
      getState().promotionPlan,
      ModelType,
      data,
      associations,
    );
  };
}

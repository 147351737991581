import { createSelector } from "reselect";

import cartSelectors from "../cart/selectors";
import productSelectors from "../product/selectors";

import siteStore from "../../services/siteStore";
import {
  isProductSpecificPromotion,
  isRecurringBundlePromotion,
} from "../../utils/promotion";

const _byId = (state) => (state.promotion ? state.promotion.byId : {});
const _meta = (state) => state.promotion.meta;

const activePromotions = createSelector(
  _byId,
  cartSelectors.activeCart,
  (byId, activeCart) => {
    if (!activeCart) {
      return [];
    }

    return (
      activeCart.activePromotionIds
        .map((id) => byId[id])
        .filter(Boolean)
        .filter(
          (promo) =>
            (!promo.activatesAt || new Date(promo.activatesAt) <= Date.now()) &&
            (!promo.expiresAt || Date.now() <= new Date(promo.expiresAt)) &&
            (!promo.storeId || promo.storeId === siteStore.storeId),
        )
        // eslint-disable-next-line array-callback-return
        .sort((a, b) => {
          if (a.priority !== b.priority) {
            return a.priority - b.priority;
          }
        })
    );
  },
);

const appliedPromotion = createSelector(
  _byId,
  cartSelectors.activeCart,
  (byId, activeCart) => {
    if (!activeCart) return null;
    return byId[activeCart.promotionId];
  },
);

const appliedProductPromotionProduct = createSelector(
  _byId,
  cartSelectors.activeCart,
  productSelectors.allProducts,
  (byId, activeCart, products) => {
    if (!activeCart) {
      return null;
    }

    const promotion = byId[activeCart.productPromotionId];
    if (!promotion) {
      return null;
    }

    return products.find((product) => product.id === promotion.productId);
  },
);

const bundlePromotion = createSelector(activePromotions, (activePromotions) =>
  activePromotions.find((promotion) => promotion.type === "BundlePromotion"),
);

// Whether promotion applies to given product (Hardcoded Postnatal right now)
const productSpecificPromotions = createSelector(
  activePromotions,
  (activePromotions) =>
    activePromotions.filter((promotion) =>
      isProductSpecificPromotion(promotion),
    ),
);

const activeProductPromotion = createSelector(
  activePromotions,
  (activePromotions) =>
    activePromotions.find((promotion) => promotion.type === "ProductPromotion"),
);

const siteWidePromotion = createSelector(activePromotions, (activePromotions) =>
  activePromotions.find(
    (promotion) =>
      promotion.type === "DiscountPromotion" &&
      !isRecurringBundlePromotion(promotion) &&
      !isProductSpecificPromotion(promotion),
  ),
);

const discountPromotions = createSelector(
  activePromotions,
  (activePromotions) =>
    activePromotions.filter(
      (promotion) => promotion && promotion.type === "DiscountPromotion",
    ),
);

export const bestEligiblePromotion = createSelector(
  _byId,
  _meta,
  (byId, meta) => byId[meta.bestEligiblePromotionId],
);

const selectors = {
  activePromotions,
  appliedProductPromotionProduct,
  appliedPromotion,
  bundlePromotion,
  activeProductPromotion,
  siteWidePromotion,
  discountPromotions,
  productSpecificPromotions,
  bestEligiblePromotion,
};

export default selectors;

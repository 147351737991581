import intl from "../services/intl";

interface TextProps {
  id: string;
  defaultMessage?: string;
  values?: Record<string, string>;
}

const Text = ({ id, defaultMessage, values }: TextProps) => {
  return <>{intl.t(id, defaultMessage, values)}</>;
};

export default Text;

import styled from "styled-components";

// Components
import MagicFade, { FadeStyles } from "../MagicFade";
import MagicLink from "../MagicLink";
import Text from "../Text";
import ProductPromotionCartItem from "./ProductPromotionCartItem";

// Utils
import { Color, Opacity } from "../../utils/style";
import LedgerItems from "../checkout/LedgerItems";

const ProductsWrapper = styled.div`
  ${FadeStyles}

  margin-top: var(--spacing-1);
`;

const EmptyCart = styled.div`
  width: 100%;
  text-align: center;
  padding: 30px 0px;
`;

const LinkContainer = styled.div`
  margin-top: 30px;
`;

const ShopLink = styled(MagicLink)`
  margin-top: 30px;
  border-bottom: 2px solid ${Color.ritualBlue};
  text-decoration: none;
  cursor: pointer;
  transition: opacity 200ms ease-in-out;

  &:hover {
    opacity: ${Opacity.light};
  }
`;

export default function CartItemList({ cartProducts, isProcessing, cartQuantity, isFlyout }) {
  return (
    <ProductsWrapper>
      <MagicFade transitionKey={!!cartQuantity}>
        <>
          {!cartQuantity ? (
            <EmptyCart>
              <Text
                id="cart.empty.message"
                defaultMessage="Your cart is empty."
              />
              <br />
              <Text
                id="cart.empty.message-second"
                defaultMessage="Fill it with something good."
              />
              <LinkContainer>
                <ShopLink to="/shop/daily-health">
                  <Text
                    id="home.featured-products.link"
                    defaultMessage="Shop All"
                  />
                </ShopLink>
              </LinkContainer>
            </EmptyCart>
          ) : (
            <LedgerItems
              activeCartProducts={cartProducts}
              disableAnimation={true}
              isProcessing={isProcessing}
              cartItemOptions={{
                hideDeleteButton: false,
                disableQuantitySelector: false,
              }}
            />
          )}

          <ProductPromotionCartItem isFlyout={isFlyout} simplified={true} />
        </>
      </MagicFade>
    </ProductsWrapper>
  );
}

import * as Castle from "@castleio/castle-js";
import * as currentUser from "../utils/currentUser";

export class CastleService {
  constructor() {
    this.castle = null;
  }

  initialize() {
    const pk = process.env.GATSBY_CASTLE_PUBLISHABLE_KEY;
    if (pk) {
      this.castle = Castle.configure({ pk });
    }
  }

  createRequestToken() {
    return this.castle ? this.castle.createRequestToken() : null;
  }

  reset() {
    this.castle = null;
  }

  async trackPageView() {
    if (!this.castle) return null;

    const user = await currentUser.getCurrentUser();
    if (user) {
      this.castle.page({ userJwt: user.castleJwt });
    }
  }
}

const castleService = new CastleService();
export default castleService;
